import { useCallback, useEffect, useRef, useState } from "react";
import BlockUi from "react-block-ui";
import { Field, useForm } from "react-final-form";
import { useDispatch } from "react-redux";
import FocusableButton from "../../../../components/Jqx/Button/FocusableButton";
import { createCheckboxColumn } from "../../../../components/Jqx/Table";
import { SubForm } from "../../../../components/SubForm";
import { mostrarNotificacao } from "../../../../reducers/notificacaoReducer";
import {
  createValidationColumn,
  validacaoRowDisponivelParaSelecao,
} from "../../../validacao/utils/validationGridUtils";
import { SubFormAmostra } from "./components/subFormaAmostra/SubFormAmostra";
import {
  consultarGeocronologiasPorIdAmostra,
  deletarGeocronologiaPorId,
  salvarGeocronologia,
} from "./services/geocrologiaServices";
import { Geocronologia, GeocronologiasProps } from "./types/geocronologiaTypes";
import IdadeAssociadaAmostra from "../../sharedComponents/IdadeAssociadaAmostra";
import Service, { GEOCRON } from "../../../../service/Service";

const Geocronologias = ({
  permitirInsercao,
  permitirVisualizacao,
  permitirEdicao,
  onMount,
  idAmostra,
  amostraSelecionada,
  idUsuarioAtual,
  isChefeProjeto,
  permitirExclusao,
  amostraOriginal,
}: GeocronologiasProps) => {
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
  const [valoresIniciaisEdicao, setValoresIniciaisEdicao] = useState({});
  const [carregamentoGlobal, setCarregamentoGlobal] = useState(true);
  const [geocronologias, setGeoCronologias] = useState<Geocronologia[]>([]);
  const [mostrarBotaoRevisao, setMostrarBotaoRevisao] = useState(false);
  const [podeInserir, setPodeInserir] = useState(permitirInsercao);
  const [podeEditar, setPodeEditar] = useState(permitirEdicao);

  const [podeExcluir, setPodeExcluir] = useState(permitirExclusao);
  const [bbSistemasIsotopicos, setBbSistemaIsotopico] = useState<any>([]);
  const [bbTipoIdade, setBbTipoIdade] = useState<any>([]);
  const [bbMaterialAnalisado, setBbMaterialAnalisado] = useState<any>([]);
  const [bbTecnicaAnalitica, setBbTecnicaAnalitica] = useState<any>([]);
  const [bbSigma, setBbSigma] = useState<any>([]);
  const [bbInterpretacao, setBbInterpretacao] = useState<any>([]);

  const [estaEditando, setEstaEditando] = useState(false);
  const [estaVisualizando, setEstaVisualizando] = useState(false);

  const tabelaRef = useRef();

  useEffect(() => {
    setPodeExcluir(permitirExclusao);
    return () => {};
  }, [permitirExclusao]);

  useEffect(() => {
    setPodeEditar(permitirEdicao);
    return () => {};
  }, [permitirEdicao]);

  useEffect(() => {
    setPodeInserir(permitirInsercao);
    return () => {};
  }, [permitirInsercao]);

  useEffect(() => {
    setDisabled(permitirVisualizacao);
    return () => {};
  }, [permitirVisualizacao]);

  const cellClass = (row: number, rowData: Geocronologia) => {
    if (!validacaoRowDisponivelParaSelecao(rowData)) {
      return row % 2 ? "checkbox-disable-even" : "checkbox-disable-odd";
    }
  };

  const cellbegineditListControl = () => {
    return true;
  };
  async function compatibilizarCampos() {
    try {
      const [
        bbSistemasIsotopicos,
        bbTecnicaAnalitica,
        bbTipoIdade,
        bbMaterialAnalisado,
        bbSigma,
        bbInterpretacao,
      ] = await Promise.all([
        Service(`/sistemas-isotopicos`, GEOCRON).query(),
        Service(`/tecnica-analitica`, GEOCRON).query(),
        Service(`/tipo-idade`, GEOCRON).query(),
        Service(`/material-analisado`, GEOCRON).query(),
        Service(`/sigma`, GEOCRON).query(),
        Service(`/interpretacao-autor`, GEOCRON).query(),
      ]);
      setBbSistemaIsotopico(bbSistemasIsotopicos?.data?.dados);
      setBbTecnicaAnalitica(bbTecnicaAnalitica?.data?.dados);
      setBbTipoIdade(bbTipoIdade?.data?.dados);
      setBbMaterialAnalisado(bbMaterialAnalisado?.data?.dados);
      setBbSigma(bbSigma?.data?.dados);
      setBbInterpretacao(bbInterpretacao?.data?.dados);
    } catch (err) {
      console.log("Erro ao consultar bibliotecas: ", err);
    }
    setCarregamentoGlobal(false);
  }

  const colunasPetrografia = [
    {
      text: "Nome da amostra",
      width: "20%",
      datafield: "nomeAmostra",
      cellsrenderer: (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) => {
        const geocronologia = geocronologias.find(
          (geocron) => geocron.id === rowdata.id
        );

        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8px;">${
          geocronologia && (geocronologia.informacoesAmostra.nomeAmostra || "")
        }</div>`;
      },
    },
    {
      text: "Nome da amostra segundo bibliografia",
      width: "30%",
      datafield: "nomeAmostraBibliografia",
      cellsrenderer: (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) => {
        const geocronologia = geocronologias.find(
          (geocron) => geocron.id === rowdata.id
        );
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8px;">${
          geocronologia &&
          (geocronologia.informacoesAmostra.nomeAmostraBibliografia || "")
        }</div>`;
      },
    },
    {
      text: "Material analisado",
      width: "30%",
      datafield: "idMaterialAnalisado",
      cellsrenderer: (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) => {
        const geocronologia = geocronologias.find(
          (geocron) => geocron.id === rowdata.id
        );
        const idMateriais = geocronologia?.idadeAssociadaAmostra.existentes.map(
          (item) => item.idMaterialAnalisado
        );
        const materiaisAnalisados = bbMaterialAnalisado
          .filter((materialAnalisado: { id: number }) =>
            idMateriais?.includes(materialAnalisado.id)
          )
          .map((materialAnalisado) => materialAnalisado.nome);
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8px;">${materiaisAnalisados?.join(
          ", "
        )}</div>`;
      },
    },
    // {
    //   text: "Status da revisão",
    //   datafield: "statusValidacao",
    //   editable: false,
    //   width: "20%",
    //   createwidget: createValidationColumn({
    //     ref: tabelaRef,
    //     method: "createwidget",
    //     hint: "Visualizar detalhes para correção",
    //   }),
    //   initwidget: createValidationColumn({
    //     ref: tabelaRef,
    //     method: "initwidget",
    //     hint: "Visualizar detalhes para correção",
    //   }),
    // },
    // ...(podeEditar
    //   ? [
    //       createCheckboxColumn({
    //         text: "Disponibilizar para revisão",
    //         dataField: "podeEnviarParaRevisao",
    //         cellClass: cellClass,
    //         beginEdit: cellbegineditListControl,
    //       }),
    //     ]
    //   : []),
  ];
  const camposPetrografia = [
    { name: "id", type: "number", map: "id" },
    {
      name: "nomeAmostra",
      type: "string",
      map: "nomeAmostra",
    },
    {
      name: "nomeAmostraBibliografia",
      type: "string",
      map: "nomeAmostraBibliografia",
    },
    {
      name: "idMaterialAnalisado",
      type: "string",
      map: "idMaterialAnalisado",
    },
  ];

  const dispararNotificacao = useCallback(
    (titulo: string, tipo: "success" | "error" = "success") => {
      dispatch(mostrarNotificacao(titulo, { tipo }, true));
    },
    [dispatch]
  );

  const consultarGeocronologiaPorAmostra = useCallback(async (idAmostra) => {
    setCarregamentoGlobal(true);
    const response = await consultarGeocronologiasPorIdAmostra(idAmostra);
    setGeoCronologias(response);
    setCarregamentoGlobal(false);
  }, []);

  const excluirGeocronologia = useCallback(
    async (e: React.FormEvent<HTMLButtonElement> & { value: number }) => {
      e.preventDefault();
      await deletarGeocronologiaPorId(e.value);

      dispararNotificacao("Geocronologia excluída com sucesso");

      await consultarGeocronologiaPorAmostra(idAmostra);
    },
    [consultarGeocronologiaPorAmostra, dispararNotificacao, idAmostra]
  );

  const form = useForm();

  useEffect(() => {
    setMostrarBotaoRevisao(permitirEdicao);

    return () => {};
  }, [permitirEdicao]);

  useEffect(() => {
    form.mutators.setValue("geocronologias", geocronologias);
  }, [geocronologias, form]);

  const handleSubmit = useCallback(
    async ({
      exibidos,
      novosElementos,
    }: {
      exibidos: Geocronologia[];
      novosElementos: { novos: Geocronologia[]; editadosPorId: Geocronologia };
    }) => {
      let data: null | Geocronologia = null;
      if (novosElementos?.editadosPorId) {
        const ids = exibidos.map((exibido) => exibido.id);
        data =
          ids
            .map((id) => novosElementos?.editadosPorId?.[`id_${id}`])
            .find((item) => item !== undefined) || null;
      } else {
        data = novosElementos?.novos?.[0];
      }
      if (!data) return;
      setCarregamentoGlobal(true);

      await salvarGeocronologia(data)
        .then(async () => {
          await consultarGeocronologiaPorAmostra(idAmostra);
          dispararNotificacao("Geocronologia salva com sucesso");
        })
        .catch(() => setCarregamentoGlobal(false))
        .finally(() => setMostrarBotaoRevisao(podeEditar));
    },
    [
      consultarGeocronologiaPorAmostra,
      dispararNotificacao,
      idAmostra,
      podeEditar,
    ]
  );

  useEffect(() => {
    (async () => await consultarGeocronologiaPorAmostra(idAmostra))();
    compatibilizarCampos();
    return () => {};
  }, [consultarGeocronologiaPorAmostra, idAmostra]);

  useEffect(() => {
    if (
      !amostraSelecionada ||
      !Object.keys(amostraSelecionada).length ||
      !idAmostra ||
      !idUsuarioAtual ||
      !amostraOriginal
    )
      return;
    (async () => {
      const { altitude, idMetodoGeoposicionamento, idVisita } =
        amostraSelecionada;

      setValoresIniciaisEdicao({
        informacoesAmostra: {
          idClasseRocha: null,
          idSubClasseRocha: null,
          idPrefixoRocha: null,
          idComplementoRocha: null,
          idUnidadeLitoestratigrafica: null,
          nomeAmostraBibliografia: null,
          unidadeLitoestratigraficaBibliografia: null,
          idRocha: null,
          toponimia: null,
          nomeAmostra: amostraOriginal,
          informacoesRelevantes: null,
        },

        idAmostra,
        altitude,
        idUsuarioColetor: idUsuarioAtual,
        idMetodoGeoposicionamento,
        idVisita,
      });
    })();

    return () => {};
  }, [amostraSelecionada, idAmostra, idUsuarioAtual, amostraOriginal]);

  const podeEditarOuExcluirGeocronologias = (geocronologias) => {
    geocronologias.forEach((element) => {
      if (isChefeProjeto || idUsuarioAtual === element?.idUsuarioColetor) {
        element.permitirExclusao = true;
        element.permitirEdicao = true;
      } else {
        element.permitirExclusao = false;
        element.permitirEdicao = false;
      }
    });
  };

  return (
    <BlockUi blocking={carregamentoGlobal}>
      <Field name={"geocronologias"} subscription={{ value: true }}>
        {({ input: { value: geocronologias = {} } }) => {
          if (geocronologias) {
            podeEditarOuExcluirGeocronologias(geocronologias);
          }
          return (
            <SubForm
              tituloForm="Geocronologias cadastradas"
              permitirInsercao={podeInserir}
              permitirVisualizacao={permitirVisualizacao}
              exibirCardTabela={true}
              permitirExclusao={!permitirVisualizacao && podeExcluir}
              permitirEdicao={!permitirVisualizacao && podeEditar}
              exibirBotaoInsercao={podeInserir}
              podeVisualizarEntidade={() => true}
              exibirTabelaSemRegistro={true}
              alternarBotoesPai={true}
              nome={"geocronologia"}
              onSubmit={handleSubmit}
              permitirGerarPdf={false}
              onBtnDeleteClick={excluirGeocronologia}
              onBtnVoltar={() => {
                setMostrarBotaoRevisao(podeEditar);
                setEstaEditando(false);
                setEstaVisualizando(false);
              }}
              onBtnAdicionar={() => setMostrarBotaoRevisao(false)}
              onBtnViewClick={() => {
                setDisabled(true);
                setEstaVisualizando(true);
              }}
              onBtnEditClick={() => {
                setDisabled(false);
                setMostrarBotaoRevisao(false);
                setEstaEditando(true);
              }}
              campos={camposPetrografia}
              colunas={colunasPetrografia}
              validarVoltar={!disabled}
              parametrosParaTableSource={{ width: "100%" }}
              itensTabela={geocronologias}
              elementos={
                {
                  existentes: geocronologias,
                } as any
              }
              labelBotaoConfirmar="Salvar"
              keepDirtyOnReinitialize={false}
              onOpen={onMount(true)}
              valoresIniciais={valoresIniciaisEdicao}
              renderForm={({ formProps }) => {
                return (
                  <>
                    <SubFormAmostra
                      amostraSelecionada={amostraSelecionada}
                      estaEditando={estaEditando}
                      formRef={formProps}
                      disabled={disabled}
                      estaVisualizando={estaVisualizando}
                    />
                    <IdadeAssociadaAmostra
                      formProps={formProps}
                      bbSigma={bbSigma}
                      bbTipoIdade={bbTipoIdade}
                      bbInterpretacao={bbInterpretacao}
                      bbSistemaIsotopico={bbSistemasIsotopicos}
                      bbTecnicaAnalitica={bbTecnicaAnalitica}
                      bbMaterialAnalisado={bbMaterialAnalisado}
                      bbIdadeMilhoesDeAnos={[]}
                      disabled={disabled}
                      permitirInsercao={podeInserir}
                    />
                  </>
                );
              }}
            />
          );
        }}
      </Field>
      {mostrarBotaoRevisao && (
        <FocusableButton
          className="float-right m-1 "
          style={{ fontSize: "smaller" }}
          disabled={true}
        >
          Enviar para revisão
        </FocusableButton>
      )}
    </BlockUi>
  );
};

export default Geocronologias;
