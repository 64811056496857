import aflora_tabela from '../../assets/img/aflora_tabela.png';
import amostra_tabela from '../../assets/img/amostra_tabela.png';
import recmin_tabela from '../../assets/img/recmin_tabela.png';
import petrografia_tabela from '../../assets/img/petrografia_tabela.png';

const formataData = (dataPublicacao) => {
    if (!dataPublicacao) return "";

    const data = new Date(dataPublicacao);
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();

    return `<div class="align-center">${dia}/${mes}/${ano}</div>`;
};

export const COLUNA_CHECKBOX_PUBLICADO = "checkboxPublicado";
export const iconCheckboxPublicacao = (selectAllPublicacao) => `fas text-secondary ${selectAllPublicacao ? "fa-check-square" : "fa-square"} `;
export const colunasAtividadesPublicacao = (calcularPorcentagemPublicadas) => [
    {
        text: "Publicar",
        datafield: COLUNA_CHECKBOX_PUBLICADO,
        width: "10%",
        columntype: 'checkbox',
        editable: true,
    },
    {
        text: "Atividade",
        datafield: "tipo",
        width: "10%",
        editable: false,
        cellsrenderer: (row, columnfield, value) => {
            let tela = "";
            if (value === "AFLORAMENTO") {
                tela += `<img style="height: 30px;width: 30px" title="Cadastros de Afloramentos" src="${aflora_tabela}" />`;
            } else if (value === "PETROGRAFIA") {
                tela += `<img style="height: 20px; width: 20px; margin-top: 4px;" title="Cadastros de análise petrográfica" src="${petrografia_tabela}" />`;
            } else if (value === "CADASTRO_AMOSTRA") {
                tela = `<img style="height: 30px;width: 30px;" title="Cadastros de Amostras" src="${amostra_tabela}" />`;
            } else if (value === "RECURSO_MINERAL") {
                tela += `<img style="height: 30px;width: 30px;" title="Cadastros de Recursos Minerais" src="${recmin_tabela}" />`;
            }
            return `<div class="d-flex justify-content-center align-items-center" style="margin-top: 2px;">${tela}</div>`;
        },
    },
    {
        text: "Registros validados", datafield: "", width: "25%", editable: false, cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
            const { totalAtividades, totalValidadas } = rowdata;
            return `<div class="registros-validados"> ${totalValidadas}/${totalAtividades}</div>`;
        }
    },
    {
        text: "Registros publicados",
        width: "35%",
        editable: false,
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
            const { totalPublicadas, totalValidadas } = rowdata;
            const porcentagem = calcularPorcentagemPublicadas(totalValidadas, totalPublicadas);
            return `<div class="progress-container">
                  <div class="registros-publicados">${totalPublicadas}/${totalValidadas}</div>
                  <div class="progress-right">
                    <div class="progress-label">${porcentagem}% Publicado</div>
                    <progress value="${porcentagem}" max="100" class="custom-progress-bar"></progress>
                  </div>
                </div>`;
        }
    },
    {
        text: "Data da última publicação", datafield: "dataPublicacao", width: "20%", editable: false,
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
            return formataData(value)
        }
    }
];