import { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Field } from "react-final-form";
import Card from "../../../components/card";
import { DropdownListField } from "../../../components/field/DropdownListField";
import { NumberInputField } from "../../../components/field/NumberInputField";
import { TextAreaField } from "../../../components/field/TextAreaField";
import { SubForm } from "../../../components/SubForm";
import { aoEnviarSubForm, setValue } from "../../../util/mutadores";
import {
  campoObrigatorioComMsgGenerica,
  validarFonteDeReferencia,
} from "../../../util/validadores";
import FonteReferencia from "../../estacoes/components/RecursoMineral/FonteReferencia/FonteReferencia";
import Epsilon from "./Epsilon";

const IdadeAssociadaAmostra = ({
  formProps,
  disabled,
  bbSistemaIsotopico,
  bbTipoIdade,
  bbTecnicaAnalitica,
  bbInterpretacao,
  bbIdadeMilhoesDeAnos,
  bbMaterialAnalisado,
  bbSigma,
  permitirInsercao,
}) => {
  const onSubmit = aoEnviarSubForm(formProps.form);
  const colunasIdadeAssociadaAmostra = [
    {
      text: "Sistema isotópico",
      datafield: "idSistemaIsotopico",
      width: "30%",
      cellsrenderer: (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) => {
        const idSistemaIsotopico = parseInt(value, 10);
        const objetoFiltrado = bbSistemaIsotopico.find(
          (elemento) => elemento.id === idSistemaIsotopico
        );
        const valor = objetoFiltrado ? objetoFiltrado.nome : "";
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
      },
    },
    {
      text: "Técnica analítica",
      datafield: "idTecnicaAnalitica",
      width: "30%",
      cellsrenderer: (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) => {
        const idTecnicaAnalitica = parseInt(value, 10);
        const objetoFiltrado = bbTecnicaAnalitica.find(
          (elemento) => elemento.id === idTecnicaAnalitica
        );
        const valor = objetoFiltrado ? objetoFiltrado.nome : "";
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
      },
    },
    {
      text: "Tipo idade",
      datafield: "idTipoIdade",
      width: "20%",
      cellsrenderer: (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) => {
        const idTipoIdade = parseInt(value, 10);
        const objetoFiltrado = bbTipoIdade.find(
          (elemento) => elemento.id === idTipoIdade
        );
        const valor = objetoFiltrado ? objetoFiltrado.nome : "";
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
      },
    },
    {
      text: "Material analisado",
      datafield: "idMaterialAnalisado",
      width: "20%",
      cellsrenderer: (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) => {
        const idMaterialAnalisado = parseInt(value, 10);
        const objetoFiltrado = bbMaterialAnalisado.find(
          (elemento) => elemento.id === idMaterialAnalisado
        );
        const valor = objetoFiltrado ? objetoFiltrado.nome : "";
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
      },
    },
  ];
  const camposIdadeAssociadaAmostra = [
    { name: "idSistemaIsotopico", type: "string", map: "idSistemaIsotopico" },
    { name: "idTecnicaAnalitica", type: "string", map: "idTecnicaAnalitica" },
    { name: "idTipoIdade", type: "string", map: "idTipoIdade" },
    { name: "idMaterialAnalisado", type: "string", map: "idMaterialAnalisado" },
  ];

  return (
    <>
      <div style={{ marginTop: "2.5rem" }}>
        <Card className="mt-3">
          <Card.Body>
            <Card.Title>Idade(s) associada(s) à amostra</Card.Title>
            <Field
              name={"idadeAssociadaAmostra"}
              subscription={{ value: true }}
            >
              {({ input: { value: idadeAssociadaAmostra = [] } }) => (
                <SubForm
                  nome={"idadeAssociadaAmostra"}
                  onSubmit={onSubmit}
                  colunas={colunasIdadeAssociadaAmostra}
                  elementos={idadeAssociadaAmostra}
                  campos={camposIdadeAssociadaAmostra}
                  validar={(valores) => validarFonteDeReferencia(valores)}
                  permitirVisualizacao={disabled}
                  podeExcluirEntidade={!disabled}
                  permitirInsercao={permitirInsercao}
                  exibirBotaoInsercao={permitirInsercao}
                  permitirEdicao={!disabled}
                  permitirExclusao={!disabled}
                  renderForm={({ formProps }) => (
                    <>
                      <Row className="my-3">
                        <Col md={4}>
                          <Field
                            component={DropdownListField}
                            name={`idSistemaIsotopico`}
                            label="Sistema isotópico"
                            dica="Selecione o sistema isotópico do dado."
                            elementos={bbSistemaIsotopico}
                            required
                            validate={campoObrigatorioComMsgGenerica(
                              "Sistema isotópico"
                            )}
                            disabled={disabled}
                          />
                        </Col>
                        <Col md={4}>
                          <Field
                            component={DropdownListField}
                            name={`idTecnicaAnalitica`}
                            label="Técnica analítica"
                            dica="Selecione a técnica analítica utilizada na geração do dado."
                            elementos={bbTecnicaAnalitica}
                            required
                            validate={campoObrigatorioComMsgGenerica(
                              "Técnica analítica"
                            )}
                            disabled={disabled}
                          />
                        </Col>
                        <Col md={4}>
                          <Field
                            component={DropdownListField}
                            name={`idTipoIdade`}
                            label="Tipo idade"
                            dica="Selecione o tipo de idade calculada."
                            elementos={bbTipoIdade}
                            required
                            validate={campoObrigatorioComMsgGenerica(
                              "Tipo idade"
                            )}
                            disabled={disabled}
                          />
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col md={2}>
                          <Field
                            component={NumberInputField}
                            casasInteiros={3}
                            casasDecimais={1}
                            name={`idadeMilhoesDeAnos`}
                            label="Idade(Ma)"
                            dica="Indique o valor da idade em milhões de anos."
                            elementos={bbIdadeMilhoesDeAnos}
                            disabled={disabled}
                          />
                        </Col>
                        <Col md={2}>
                          <Field
                            component={NumberInputField}
                            casasInteiros={3}
                            casasDecimais={1}
                            name={`erroCalculoIdade`}
                            label="Erro(+/-)"
                            dica="Indique o erro associado ao cálculo da idade."
                            disabled={disabled}
                          />
                        </Col>
                        <Col md={4}>
                          <Field
                            component={DropdownListField}
                            elementos={bbSigma}
                            name={`idSigma`}
                            label="Sigma"
                            dica="Indique o nível do erro apresentado."
                            disabled={disabled}
                          />
                        </Col>
                        <Col md={4}>
                          <Field
                            component={DropdownListField}
                            name={`idMaterialAnalisado`}
                            label="Material analisado"
                            dica="Selecione o material utilizado na análise."
                            elementos={bbMaterialAnalisado}
                            required
                            validate={campoObrigatorioComMsgGenerica(
                              "Tipo idade"
                            )}
                            disabled={disabled}
                          />
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col md={2}>
                          <Field
                            component={NumberInputField}
                            casasInteiros={4}
                            casasDecimais={0}
                            name={`numeroDePontos`}
                            label="Número de pontos"
                            dica="Indique o número de pontos utilizados no cálculo da idade."
                            disabled={disabled}
                          />
                        </Col>
                        <Col md={2}>
                          <Field
                            component={NumberInputField}
                            casasInteiros={1}
                            casasDecimais={3}
                            name={`mswd`}
                            label="MSWD"
                            dica="Indique a variação média quadrática (do inglês, MSWD) associada ao cálculo da idade."
                            disabled={disabled}
                          />
                        </Col>
                        <Col md={4}>
                          <Field
                            component={NumberInputField}
                            casasInteiros={2}
                            casasDecimais={2}
                            name={`probabilidadeDeAjuste`}
                            label="Probabilidade de ajuste/concordância"
                            dica="Indique a probabilidade de ajuste da regressão ou concórdia dos dados."
                            disabled={disabled}
                          />
                        </Col>
                        <Col md={4}>
                          <Field
                            component={DropdownListField}
                            name={`idInterpretacaoAutor`}
                            label="Interpretação"
                            required
                            dica="Seleciona a interpretação dada ao dado, conforme o(s) autor(es)."
                            validate={campoObrigatorioComMsgGenerica(
                              "Interpretação"
                            )}
                            elementos={bbInterpretacao}
                            disabled={disabled}
                          />
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col md={12}>
                          <Field
                            component={TextAreaField}
                            name={"informacoesRelevantes"}
                            label="Informações relevantes"
                            dica="Descreva/relacione outras informações relevantes relacionadas aos processos de alteração hidrotermal."
                            maxLength={2000}
                            disabled={disabled}
                          />
                        </Col>
                      </Row>
                      <FonteReferencia
                        permitirEdicao={permitirInsercao}
                        formPai={formProps}
                        mutators={{ setValue }}
                        origem={"IdadeAssociadaAmostra"}
                        modoDefinitivo={true}
                      />
                      <Epsilon
                        bbSistemaIsotopico={bbSistemaIsotopico}
                        bbTecnicaAnalitica={bbTecnicaAnalitica}
                        bbTipoIdade={bbTipoIdade}
                        formProps={formProps}
                        disabled={disabled}
                        permitirInsercao={permitirInsercao}
                      />
                    </>
                  )}
                />
              )}
            </Field>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default IdadeAssociadaAmostra;
