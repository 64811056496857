import Service, { GEOCRON } from "../../../../../service/Service";
import { swalErro } from "../../../../../util/validadores";
import { Geocronologia } from "../types/geocronologiaTypes";

export const salvarGeocronologia = async (data: Geocronologia) => {
  await Service("/geocronologias", GEOCRON)
    .post(data)
    .catch(async (error) => {
      const errorResponse = error?.response?.data?.erros?.[0] || error?.message;
      await swalErro({
        title: "Erro ao salvar a geocronologia: " + errorResponse,
      });
      throw errorResponse;
    });
};

export const consultarGeocronologiasPorIdAmostra = async (
  idAmostra: number
): Promise<Geocronologia[] | []> => {
  if (!idAmostra) return [];
  const response = await Service(`/geocronologias/amostra`, GEOCRON)
    .get(idAmostra)
    .catch((_error) => {
      swalErro({ title: "Erro ao consultar as geocronologias." });
    });

  return response?.data?.dados ? response?.data?.dados : [];
};

export const deletarGeocronologiaPorId = async (id: number) => {
  if (!id) return swalErro({ title: "Id inválido para exclusão" });
  await Service(`/geocronologias/deletar-geocronologia/${id}`, GEOCRON)
    .delete()
    .catch((error: { response: { data: { erros: any[] } }; message: any }) => {
      const errorResponse = error?.response?.data?.erros?.[0] || error?.message;
      swalErro({
        title: "Erro ao excluir geocronologia" + errorResponse,
      });
      throw errorResponse;
    });
};
