import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Field } from "react-final-form";
import { aoEnviarSubForm } from "../../../util/mutadores";
import Card from "../../../components/card";
import { SubForm } from "../../../components/SubForm";
import { DropdownListField } from "../../../components/field/DropdownListField";
import { campoObrigatorioComMsgGenerica } from "../../../util/validadores";
import { NumberInputField } from "../../../components/field/NumberInputField";
import { memo } from "react";

const Epsilon = ({
    bbTipoIdade,
    bbTecnicaAnalitica,
    bbSistemaIsotopico,
    disabled,
    formProps,
    permitirInsercao,
}) => {
    const onSubmit = aoEnviarSubForm(formProps.form);

    const colunasIdadeAssociadaAmostra = [
        {
            text: "Épsilon(t)",
            datafield: "epsilonT",
            width: "10%",
            cellsrenderer: (
                row,
                columnfield,
                value,
                defaulthtml,
                columnproperties,
                rowdata
            ) => {
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${value}</div>`;
            },
        },
        {
            text: "Épsilon(0)",
            datafield: "epsilon0",
            width: "10%",
            cellsrenderer: (
                row,
                columnfield,
                value,
                defaulthtml,
                columnproperties,
                rowdata
            ) => {
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${value}</div>`;
            },
        },
        {
            text: "Idade(Ma)",
            datafield: "idadeMilhoesDeAnosEpsilon",
            width: "20%",
            cellsrenderer: (
                row,
                columnfield,
                value,
                defaulthtml,
                columnproperties,
                rowdata
            ) => {
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${value}</div>`;
            },
        },
        {
            text: "Sistema isotópico",
            datafield: "idSistemaIsotopicoEpisilon",
            width: "20%",
            cellsrenderer: (
                row,
                columnfield,
                value,
                defaulthtml,
                columnproperties,
                rowdata
            ) => {
                const sistemaIsotopico = parseInt(value, 10);
                const objetoFiltrado = bbSistemaIsotopico.find(
                    (elemento) => elemento.id === sistemaIsotopico
                );
                const valor = objetoFiltrado ? objetoFiltrado.nome : "";
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            },
        },
        {
            text: "Técnica analítica",
            datafield: "idTecnicaAnaliticaEpsilon",
            width: "20%",
            cellsrenderer: (
                row,
                columnfield,
                value,
                defaulthtml,
                columnproperties,
                rowdata
            ) => {
                const tecnicaAnalitica = parseInt(value, 10);
                const objetoFiltrado = bbTecnicaAnalitica.find(
                    (elemento) => elemento.id === tecnicaAnalitica
                );
                const valor = objetoFiltrado ? objetoFiltrado.nome : "";
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            },
        },
        {
            text: "Tipo idade",
            datafield: "idTipoIdadeEpsilon",
            width: "20%",
            cellsrenderer: (
                row,
                columnfield,
                value,
                defaulthtml,
                columnproperties,
                rowdata
            ) => {
                const tipoIdade = parseInt(value, 10);
                const objetoFiltrado = bbTipoIdade.find(
                    (elemento) => elemento.id === tipoIdade
                );
                const valor = objetoFiltrado ? objetoFiltrado.nome : "";
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            },
        },
    ];
    const camposIdadeAssociadaAmostra = [
        { name: "epsilonT", type: "number", map: "epsilonT" },
        { name: "epsilon0", type: "number", map: "epsilon0" },
        { name: "idadeMilhoesDeAnosEpsilon", type: "number", map: "idadeMilhoesDeAnosEpsilon" },
        {
            name: "idSistemaIsotopicoEpisilon",
            type: "string",
            map: "idSistemaIsotopicoEpisilon",
        },
        {
            name: "idTecnicaAnaliticaEpsilon",
            type: "string",
            map: "idTecnicaAnaliticaEpsilon",
        },
        { name: "idTipoIdadeEpsilon", type: "string", map: "idTipoIdadeEpsilon" },
    ];

    return (
        <>
            <div style={{ marginTop: "2.5rem" }}>
                <Card className="mt-3">
                    <Card.Body>
                        <Field name={"epsilons"} subscription={{ value: true }}>
                            {({ input: { value: epsilons = [] } }) => (
                                <>
                                    <SubForm
                                        nome={"epsilons"}
                                        tituloForm="Épsilon"
                                        onSubmit={onSubmit}
                                        exibirTabelaSemRegistro={false}
                                        colunas={colunasIdadeAssociadaAmostra}
                                        elementos={epsilons}
                                        campos={camposIdadeAssociadaAmostra}
                                        podeExcluirEntidade={!disabled}
                                        permitirInsercao={permitirInsercao}
                                        exibirBotaoInsercao={permitirInsercao}
                                        permitirEdicao={!disabled}
                                        permitirExclusao={!disabled}
                                        permitirVisualizacao={disabled}
                                        renderForm={({ formProps }) => (
                                            <>
                                                <Row className="my-3">
                                                    <Col md={4}>
                                                        <Field
                                                            component={NumberInputField}
                                                            casasInteiros={2}
                                                            casasDecimais={2}
                                                            permitirNegativos={false}
                                                            name={`epsilonT`}
                                                            label="Épsilon(t)"
                                                            required
                                                            validate={campoObrigatorioComMsgGenerica(
                                                                "Épsilon(t)"
                                                            )}
                                                            dica="Insira o valor de épsilon calculado para a idade de referência."
                                                            disabled={disabled}
                                                        />
                                                    </Col>
                                                    <Col md={4}>
                                                        <Field
                                                            component={NumberInputField}
                                                            casasInteiros={2}
                                                            casasDecimais={2}
                                                            name={`epsilon0`}
                                                            label="Épsilon(0)"
                                                            dica="Insira o valor de épsilon para o tempo atual."
                                                            disabled={disabled}
                                                        />
                                                    </Col>
                                                </Row>
                                                <span>Idade de referência para épsilon(t)</span>
                                                <fieldset
                                                    className="border p-2"
                                                    style={{ borderRadius: "4px" }}
                                                >
                                                    <Row className="my-3">
                                                        <Col md={3}>
                                                            <Field
                                                                component={NumberInputField}
                                                                casasInteiros={3}
                                                                casasDecimais={1}
                                                                name={`idadeMilhoesDeAnosEpsilon`}
                                                                label="Idade(Ma)"
                                                                dica="Insira a idade de referência em milhões de anos para o épsilon (t)."
                                                                disabled={disabled}
                                                            />
                                                        </Col>
                                                        <Col md={3}>
                                                            <Field
                                                                component={DropdownListField}
                                                                name={`idSistemaIsotopicoEpisilon`}
                                                                label="Sistema isotópico"
                                                                dica="Seleciona o sistema isotópico da idade de referência do épsilon (t)."
                                                                elementos={bbSistemaIsotopico}
                                                                disabled={disabled}
                                                            />
                                                        </Col>
                                                        <Col md={3}>
                                                            <Field
                                                                component={DropdownListField}
                                                                name={`idTecnicaAnaliticaEpsilon`}
                                                                label="Técnica analítica"
                                                                dica="Selecione  a técnica analítica da idade de referência do épsilon (t)."
                                                                elementos={bbTecnicaAnalitica}
                                                                disabled={disabled}
                                                            />
                                                        </Col>
                                                        <Col md={3}>
                                                            <Field
                                                                component={DropdownListField}
                                                                name={`idTipoIdadeEpsilon`}
                                                                label="Tipo idade"
                                                                dica="Selecione o tipo da idade de referência do épsilon (t)."
                                                                elementos={bbTipoIdade}
                                                                disabled={disabled}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </fieldset>
                                            </>
                                        )}
                                    />
                                </>
                            )}
                        </Field>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

export default memo(Epsilon, () => true);
