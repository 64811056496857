import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import swal from "sweetalert";
import PropTypes from "prop-types";
import { PrimaryButton } from "../Jqx/Button";
import { createControlColumn, Table } from "../Jqx/Table";
import { Field, Form, useForm } from "react-final-form";
import arrayMutators from "final-form-arrays";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import converterVetorParaObjetoIndexado from "../../util/converterVetorParaObjetoIndexado";
import { camposForamEditados } from "../../util/formFoiEditado"
import isNumber from "lodash/isNumber";
import { setValue } from "../../util/mutadores";
import { flattenObject } from "../../util/flattenObject";
import { FORM_ERROR } from "final-form";
import { HiddenField } from "../field/HiddenField";
import NotificadorErrosForm from "../NotificadorErrosForm";
import { uniqueId } from "lodash";
import { scrollAoErro, swalConfirmarExclusao } from "../../util/validadores";
import Card from "../card";
import GridAppendButton from "../Jqx/Button/GridAppendButton";
import { COLUNA_BAIXAR, TIPO_ATIVIDADE_AFLORAMENTO, TIPO_ATIVIDADE_AMOSTRAS, TIPO_ATIVIDADE_PETROGRAFIA, TIPO_ATIVIDADE_RECURSO_MINERAL } from "../../util/constantes";

const DEBUG = true;

const DELAY_EXIBICAO_TABELA_PADRAO = 1500;

const IDX_NO_VETOR_ORIGINAL = Symbol("IDX_NO_VETOR_ORIGINAL");

let ULTIMO_ID_TEMPORARIO = -1;

const COLUNA_EXCLUIR = "idExibir";
const COLUNA_PODE_EXCLUIR = "podeExcluir";
const COLUNA_PODE_EDITAR = "podeEditar";
const COLUNA_EDITAR = "idEditar";
const COLUNA_VISUALIZAR = "idVisualizar";
const COLUNA_MOBILE = "modoEdicao";
const COLUNA_COMPATIBILIZACAO = "permitirCompatibilizacao"
const COLUNA_CHECKBOX_MULT_SELECAO = "checkboxMultiplaSelecao"

// **********************************************************************

// Assume que há dois estados possíveis: editando ou visualizando. Não sendo possível ter as duas opções ao mesmo tempo
export const getPropsPermissao = permitirEdicao => ({
  permitirInsercao: permitirEdicao,
  exibirBotaoInsercao: permitirEdicao,
  permitirEdicao: permitirEdicao,
  permitirExclusao: permitirEdicao,
  permitirVisualizacao: !permitirEdicao,
});

const tratarNomeClasse = (nome) => {
  let tempNome = ""
  if (nome.includes('.')) {
    tempNome = nome.replace(/\./g, '-');
    return tempNome;
  } else {
    return nome;
  }
};

const podeExcluirEntidadePadrao = (entidade) => {
  if (!entidade) return true;
  if (entidade?.id != null && entidade?.id <= 0) return true;
  if (undefined === entidade[COLUNA_PODE_EXCLUIR]) return true;
  return !!entidade[COLUNA_PODE_EXCLUIR];
};

const podeEditarEntidadePadrao = (entidade) => {
  if (entidade && entidade.modoEdicao === 'MOBILE') return false;
  if (!entidade) return true;
  if (entidade?.id != null && entidade?.id <= 0) return true;
  if (undefined === entidade[COLUNA_PODE_EDITAR]) return true;
  return !! entidade[COLUNA_PODE_EDITAR];
};

const podeCompatibilizarPadrao = (entidade) => {
  if(entidade && entidade.tipoAtividade === 'AFLORAMENTO') return true;
  if (!entidade) return false;
  return false
}

const podeVisualizarEntidadePadrao = (entidade) => true; // Se habilitado, por padrão a visualização é sempre permitida

const podeAlterarModoEdicaoPadrao = (entidade) => {
  if (entidade && entidade.modoEdicao === 'MOBILE') return true;
};

// **** utils
export const SubFormPrefixContext = React.createContext("");

export const resolverExibidos = ({
  existentes = [],
  editadosPorId = {},
  idsExcluidos = [],
  novos = [],
}) => {
  let exibidos = [...existentes]; // comecamos com todos os elementos

  // Necessário pois quando um valor é excluido do form é setado seu valor como nulo na lista
  // Para verificar se pode tirar o trecho do código que define o valor nulo é necessário retestar o sistema
  // como um todo uma vez que não se sabe o real motivo de setar nulo em vez de simplesmente remover da lista
  let novosNaoNulos = novos.filter(n=>n);
  exibidos = exibidos
    .filter((el) => !idsExcluidos.includes(Number(el?.id))) // tira os excluidos
    .concat(novosNaoNulos) // inclui os novos
    .filter((el) => null !== el);

  for (const [id, val] of Object.entries(editadosPorId)) {
    let idComoNumero = id?.replace("id_", "");
    // eslint-disable-next-line
    let idxNoVetorOriginal = exibidos.findIndex((el) => el?.id == idComoNumero);
    if (idxNoVetorOriginal > -1) {
      exibidos[idxNoVetorOriginal] = val;
    }
  }
  return exibidos;
};

const normalizarObjetos = (values = []) => {

  let novosObj = []
  let keys = []

  if (values.length > 1) {
    values.map((novo = null) => {
      if (novo) {
        const objKeys = Object.keys(novo)
        if (objKeys.length > keys.length) keys = objKeys
      }
      return novo;
    })

    novosObj = values.map((novos = null) => {
      keys.forEach(({ key }) => {
        if (novos && !novos[key]) novos[key] = null;
      })
      return novos;
    })

    novosObj = novosObj.sort((a, b) => b?.id - a?.id);
    return novosObj;
  }
  return values;
}

const fecharForm = async ({
  setFormOpen,
  onClose,
  setEstadoEdicao,
  scrollAoFechar,
  tableRef,
}) => {
  setFormOpen(false);
  setEstadoEdicao({
    estaEditando: false,
  });
  onClose && onClose();

  // Scroll na página para centralizar SubForm recém fechado
  if (scrollAoFechar) {
    const selector = tableRef?.current?._componentSelector;
    // ?.parentElement?.parentElement busca o Card que geralmente envolve um SubForm
    selector &&
      document
        .querySelector(selector)
        ?.parentElement?.parentElement?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
  }

};

const abrirForm = ({ setFormOpen, onOpen }) => {
  setFormOpen(true);
  onOpen && onOpen();
};

// **********************************************************************
// Componentes



export const BotoesPadrao = ({ voltar, disabled, nome, uid, mostrarBotaoConfirmar, labelBotaoConfirmar, labelBotaoVoltar, nomeClasse, onBtnConfirm, onBtnVoltar }) => {
  nomeClasse = tratarNomeClasse(nome);
  const form = useForm();


  const onVoltar =() =>{
    voltar(form)
    onBtnVoltar && onBtnVoltar()
  }

  return (
    <Row className={`subform-btns-padrao subform-btns-padrao-${nomeClasse}`}>
      <Col>
        {
          mostrarBotaoConfirmar && (
            <PrimaryButton
              className={`float-right m-2 subform-btn-confirmar subform-btn-confirmar-${nomeClasse}`}
              onClick={() => {
                onBtnConfirm(form);
                form.submit();
                scrollAoErro(document.getElementById(uid));
              }}
              titulo={labelBotaoConfirmar}
              disabled={disabled}
            />
          )
        }
        <PrimaryButton
          className={`float-right m-2 subform-btn-voltar subform-btn-voltar-${nomeClasse}`}
          onClick={() => onVoltar() }
          titulo={labelBotaoVoltar}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
};

const SubForm = ({
  // Props básicas
  renderForm,
  campos,
  colunas,
  nome,

  /**
   * Ref para o componente de Table.
   */
  tableRef,

  elementos = {
    existentes: [],
    novos: [],
    editadosPorId: {},
    idsExcluidos: [],
  },
  // Sobrescreve os elementos que normalmente aparecem na tabela.
  itensTabela = undefined,
  valoresIniciais,

  // Eventos
  onSubmit,
  onOpen,
  onClose,
  validar,
  onBtnEditClick,
  onBtnDeleteClick,
  onBtnViewClick,
  onBtnGerarPdfClick,
  onBtnChangeEditModeClick,
  exibirBotaoModoEdicao,
  aoCarregarExibidos,

  // Controle de layout/funcionalidades
  permitirInsercao,
  permitirEdicao,
  permitirGerarPdf,
  permitirExclusao,
  permitirVisualizacao,
  permitirAlterarModoEdicao,
  permitirCompatibilizacao,
  exibirBotaoInsercao,
  exibirBotaoConfirmar,
  /**
   * Parâmetros que serão usados na criação do dataadapter do JqxGrid. Este
   * prop será usado uma única vez no primeiro render da tabela.
   */
  parametrosParaTableSource,
  ordenacaoPersonalizada,
  criarDecoradores = () => [],
  /**
   * Boolean ou função que recebe uma entidade e retorna um boolean dizendo se ela pode
   * ser excluída.
   */  
  podeExcluirEntidade = podeExcluirEntidadePadrao,
  /**
   * Boolean ou função que recebe uma entidade e retorna um boolean dizendo se ela pode
   * ser editada.
   */
  podeEditarEntidade = podeEditarEntidadePadrao,
  /**
   * Boolean ou função que recebe uma entidade e retorna um boolean dizendo se ela pode
   * ser visualizada.
   */
  podeVisualizarEntidade = podeVisualizarEntidadePadrao,
  /**
   * Boolean ou função que recebe uma entidade e retorna um boolean dizendo se seu modo de
   * edição mode ser alterado.
   */
  podeAlterarModoEdicao = podeAlterarModoEdicaoPadrao,

  podeCompatibilizar,
  compatibilizar,

  tituloForm = "",
  tituloFormMenor = false,
  exibirAsterisco = false,
  formSubscription = undefined,
  keepDirtyOnReinitialize = false,
  delayParaExibicaoTabela = DELAY_EXIBICAO_TABELA_PADRAO,
  alternarBotoesPai = true,
  validarVoltar = true,
  naoValidarCamposVoltar = [],
  scrollAoFechar,
  tabIndexAdicionar = -1,
  exibirTabelaSemRegistro,
  labelBotaoConfirmar,
  onBtnVoltar = ()=>{},
  onBtnAdicionar = ()=>{},
  labelBotaoVoltar,
  onBtnConfirm,
  confirmarExclusao,
  tableProps,
  exibirCardTabela = false,
  nomeClasse = "",
  aoConfirmarVoltar = () => { },

  // Estado
  formularioAberto,
  exibirBotoesPadrao = true,
  fecharFormAoSalvar = true,

  validarExclusaoAtividade = undefined,
  modoSubFormComGrid = false,
  colunaCheckboxGrid = false,
  rowDisponivelParaSelecao=(row)=>{},
  nomeColunaCheckbox= "Selecionar",
  aoSelecionarTodosCheckbox =()=>{},
  rowDisponivelParaEdicaoOuExclusao=(row)=>true,
  
}) => {
  const uid = useRef(uniqueId("subform-")).current; // id de elemento aplicado no DOM
  const prefixoSubForm = useContext(SubFormPrefixContext); 

  // Update: O source da tabela costumava ser recriado toda vez que o conteúdo
  // da tabela mudava, mas isso causava databinds custosos dentro do JqxGrid.
  // Agora mantemos somente uma lista de itens que muda quando necessário, e o
  // nosso componente de Table é responsável por gerenciar o source.
  const [tableItems, setTableItems] = useState([]);  

  const [elementosExibidos, setElementosExibidos] = useState([]);
  const [elementosExibidosPorId, setElementosExibidosPorId] = useState({});
  const [prontoParaExibir, setProntoParaExibir] = useState(false);
  const decoradores = useMemo(() => {
    return criarDecoradores(prefixoSubForm);
  }, [prefixoSubForm, criarDecoradores]);

  const mutadores = useMemo(() => {
    return { ...arrayMutators, setValue };
  }, []);

  // **********************************************************************
  // Ações do SubForm

  /**
   * @type {React.MutableRefObject<Table>}
   */
  const _tableRef = useRef(null);

  /**
   * Ref to the Jqx Grid directly. This is for convenience, since the same
   * ref is available as the "tableRef" property of the Table's ref. Calling
   * "tableRef.current?.tableRef" also gives this object.
   * @type {React.MutableRefObject<JqxGrid>}
   */
  const jqxRef = useRef(null);

  const [isFormOpen, setFormOpen] = useState(formularioAberto);
  const [prefixoAtual, setPrefixoAtual] = useState("");

  // Quando o valor de formularioAberto for alterado externamente, precisamos atualizar o estado interno :)
  useEffect(() => {    
    setFormOpen(formularioAberto);
    if (formularioAberto) {
      abrirForm({ setFormOpen, onOpen });
    } else {
      fecharForm({ setFormOpen, setEstadoEdicao, onClose, tableRef: _tableRef.current?.tableRef });
    }
  }, [formularioAberto, onOpen, onClose, setFormOpen, _tableRef]);

  const [estadoEdicao, setEstadoEdicao] = useState({
    estaEditando: false,
    estaVisualizando: false,
    id: null,
    ehTemporario: false,
  });

  const persistirDados = useCallback(
    async (novosElementos, alteracoesExtras = {}, dadosAdicionais = {}) => {
      const prefixo = nome;
      const { existentes, ...resto } = novosElementos;
      const alteracoes = {
        ...flattenObject(resto, prefixo),
        ...flattenObject(alteracoesExtras, prefixo),
      };

      onSubmit &&
        (await onSubmit({
          novosElementos: novosElementos,
          alteracoesForm: alteracoes,
          exibidos: resolverExibidos(novosElementos),
          prefixoAtual: prefixo,
          dadosAdicionais,
        }));
      DEBUG &&
        console.log(`Subform ${nome} alteracoesForm`, {
          alteracoesForm: alteracoes,
        });
    },
    [onSubmit, nome]
  );

  useEffect(() => {
    const quantosNovos = !!elementos.novos ? elementos.novos.length : 0;
    const prefixoNovo =
      estadoEdicao.estaEditando && !estadoEdicao.ehTemporario
        ? `${nome}.editadosPorId[id_${estadoEdicao?.id}]`
        : // ? `${nome}.editadosPorId[${estadoEdicao.id}L]`
          `${nome}.novos[${quantosNovos}]`;

    const prefixoNovoContextualizado = `${
      prefixoSubForm.length > 0 ? prefixoSubForm + "." : ""
    }${prefixoNovo}`;

    if (prefixoNovoContextualizado !== prefixoAtual) {
      setPrefixoAtual(prefixoNovoContextualizado);
    }
  }, [
    estadoEdicao.estaEditando,
    estadoEdicao.ehTemporario,
    estadoEdicao.id,
    prefixoSubForm,
    elementos,
    nome,
    prefixoAtual,
  ]);

  // Estas funções costumavam ser criadas com useCallback, mas isso fazia elas serem recriadas com bastante frequência,
  // e isso era um problema porque tem um effect que usa essas funções como dependência para criar o array de colunas
  // da tabela. A tabela do JqxGrid demora para redefinir colunas.

  const excluirId = useRef(excluirIdFunctionGenerator(elementos, persistirDados, onBtnDeleteClick, confirmarExclusao, _tableRef, validarExclusaoAtividade));
  useEffect(() => {
    excluirId.current = excluirIdFunctionGenerator(elementos, persistirDados, onBtnDeleteClick, confirmarExclusao, _tableRef, validarExclusaoAtividade);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementos, persistirDados, onBtnDeleteClick, confirmarExclusao]);

  const editarId = useRef(editarIdFunctionGenerator(onOpen, setFormOpen, onBtnEditClick, setEstadoEdicao, abrirForm, _tableRef));
  useEffect(() => {
    editarId.current = editarIdFunctionGenerator(onOpen, setFormOpen, onBtnEditClick, setEstadoEdicao, abrirForm, _tableRef);
  }, [onOpen, setFormOpen, onBtnEditClick])
  
  const visualizarId = useRef(visualizarIdFunctionGenerator(onOpen, setFormOpen, onBtnViewClick, setEstadoEdicao, abrirForm, _tableRef));
  useEffect(() => {
    visualizarId.current = visualizarIdFunctionGenerator(onOpen, setFormOpen, onBtnViewClick, setEstadoEdicao, abrirForm, _tableRef);
  }, [onOpen, setFormOpen, onBtnViewClick, _tableRef]);
  
  // Estas funções que verificam se um elemento pode ser editado ou deletado eram lidas direto do
  // props, mas agora também são refs pelo mesmo motivo de evitar de rodar o effect que cria as colunas.
  const podeEditarEntidadeRef = useRef(wrapPermission(podeEditarEntidade, podeEditarEntidadePadrao));
  const podeExcluirEntidadeRef = useRef(wrapPermission(podeExcluirEntidade, podeExcluirEntidadePadrao));
  const podeVisualizarEntidadeRef = useRef(wrapPermission(podeVisualizarEntidade, podeVisualizarEntidadePadrao));
  const podeAlterarModoEdicaoRef = useRef(wrapPermission(podeAlterarModoEdicao, podeAlterarModoEdicaoPadrao));
  const podeCompatibilizarRef = useRef(wrapPermission(podeCompatibilizar, podeCompatibilizarPadrao));
  // These props may be booleans or functions that return booleans. Internally, we want the refs to
  // always be refs of functions. So we wrap any value that's not a function into a function.
  useEffect(() => {
    podeEditarEntidadeRef.current = wrapPermission(podeEditarEntidade, podeEditarEntidadePadrao);
  }, [podeEditarEntidade]);
  useEffect(() => {
    podeExcluirEntidadeRef.current = wrapPermission(podeExcluirEntidade, podeExcluirEntidadePadrao);
  }, [podeExcluirEntidade]);
  useEffect(() => {
    podeVisualizarEntidadeRef.current = wrapPermission(podeVisualizarEntidade, podeVisualizarEntidadePadrao);
  }, [podeVisualizarEntidade]);
  useEffect(() => {
    podeAlterarModoEdicaoRef.current = wrapPermission(podeAlterarModoEdicao, podeAlterarModoEdicaoPadrao)
  }, [podeAlterarModoEdicao]);

  const alterarModoEdicao = async (idAtividade) => {
    const result = await swal({
      title: `Essa opção irá desabilitar alterações dessa atividade no dispositivo móvel, deseja prosseguir mesmo assim?`,
      icon: "warning",
      buttons: {
        cancel: "Não, cancelar",
        confirm: { text: "Sim, desejo prosseguir!", className: "btn-danger" },
      },
    });

    if (result) {
      const event = new TableActionEvent(idAtividade);
      onBtnChangeEditModeClick(event, _tableRef.current?.tableRef);
    }
  };

  const abrirFormularioEmBranco = useCallback(() => {
    abrirForm({ setFormOpen, onOpen });
    onBtnAdicionar && onBtnAdicionar()
    // maybe clean the form?
  }, [onOpen, setFormOpen, onBtnAdicionar]);

  const enviarForm = useCallback(
    async (values) => {
      let novoRegistro,
        dadosAdicionais = { estadoEdicao };
      if (estadoEdicao.estaEditando) {
        if (estadoEdicao.ehTemporario) {
          const novos = elementos.novos.filter(
            (e) => null === e || parseInt(e.id) !== parseInt(estadoEdicao.id)
          );

          const novosNormalizados = normalizarObjetos([values, ...novos]);
          const indiceNovoEditado = novosNormalizados.findIndex(
            (n) => parseInt(n.id) === parseInt(estadoEdicao.id)
          );
          dadosAdicionais.registroEditado = `${nome}.novos[${parseInt(
            indiceNovoEditado
          )}]`;

          novoRegistro = {
            ...elementos,
            novos: novosNormalizados,
          };
        } else {
          dadosAdicionais.registroEditado = `${nome}.editadosPorId.id_${estadoEdicao.id}`;

          novoRegistro = {
            ...elementos,
            editadosPorId: {
              ...elementos.editadosPorId,
              // https://github.com/final-form/react-final-form/blob/master/docs/faq.md#why-cant-i-have-numeric-keys-in-an-object
              // [estadoEdicao.id + "L"]: values,
              ["id_" + estadoEdicao.id]: values,
            },
          };
        }
      } else {
        novoRegistro = {
          ...elementos,
          novos: [...(elementos?.novos ?? []), values],
        };
      }

      let erroAoPersistirDados = false;
      await persistirDados(novoRegistro, undefined, dadosAdicionais).catch(
        (err) => {
          DEBUG &&
            console.error(
              "Erro ao persistir dados durante envio do form:",
              err
            );
          erroAoPersistirDados = true;
        }
      );
      if (erroAoPersistirDados) return; // Não fechar form caso ocorra um erro ao persistir dados

      // setEstadoEdicao({
      //   estaEditando: false,
      //   ehTemporario: false,
      //   id: null,
      // });

      if (fecharFormAoSalvar === false) return;

      fecharForm({
        setFormOpen,
        onClose,
        setEstadoEdicao,
        scrollAoFechar,
        tableRef: jqxRef,
      });
    },
    [
      onClose,
      elementos,
      persistirDados,
      estadoEdicao,
      scrollAoFechar,
      nome,
      fecharFormAoSalvar,
    ]
  );

  const onBtnBackClick = useCallback(
    async (form) => {
      if (validarVoltar) {
        const result = await camposForamEditados(form, naoValidarCamposVoltar);
        DEBUG &&
          console.log(
            "não validar",
            form,
            form.getState(),
            form.getState().modified,
            form.getState().dirtyFields
          );
        if (result === null) return false;
      }
      aoConfirmarVoltar(form);
      fecharForm({
        setFormOpen,
        onClose,
        setEstadoEdicao,
        scrollAoFechar,
        tableRef: jqxRef,
      });
    },
    [
      validarVoltar,
      aoConfirmarVoltar,
      onClose,
      scrollAoFechar,
      naoValidarCamposVoltar,
    ]
  );

  // **********************************************************************
  // Configuração do grid

  const ultimaAtualizacao = useRef(Date.now());

  useEffect(() => {
    const { existentes = [] } = elementos;

    let idx = 0;
    for (const el of existentes) {
      el[IDX_NO_VETOR_ORIGINAL] = idx++;
    }

    let exibidos = resolverExibidos(elementos);
    setElementosExibidos(exibidos);
    setElementosExibidosPorId(converterVetorParaObjetoIndexado(exibidos));
    if (aoCarregarExibidos) {
      aoCarregarExibidos(exibidos);
    }

    // Desenhar o jQXGrid no DOM aparentemente é muito custoso, e cada rerenderização
    // piora isso. Por isso assumimos um tempo (delayParaExibicaoTabela) para deixar
    // as rerenderizações necessárias acontecerem e só depois exibimos a tabela
    ultimaAtualizacao.current = Date.now();
    let mounted = true;

    // Nós não precisamos do prontoParaExibir para esconder a tabela, mas aparentemente
    // esse passo é importante para corretamente exibir os elementaos da tabela.
    // Isso ainda precisa de investigação.
    const exibirTabela = () => {
      if (!prontoParaExibir) {
        if (Date.now() - ultimaAtualizacao.current > 0) {
          mounted && setProntoParaExibir(true);
        } else {
          setTimeout(exibirTabela, 0);
        }
      }
    };
    setTimeout(exibirTabela, 0);

    return () => {
      mounted = false;
    }; // evita tentar atualizar hook em um componente desmontado
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementos, aoCarregarExibidos]);

  const createDatafields = () => {
    const dataFields = [...campos];

    // Costumávamos incluir diferentes datafields somente em certas situações,
    // mas depois do refactor do SubForm para reduzir a quantidade de renders,
    // isso passou a não funcionar tão bem. Os callbacks do createIconButton
    // dentro das colunas não estavam passando o id corretamente, e o id vinha
    // destes datafields extras. Acredito que não há problema em
    // sempre incluir todos os datafields, e isso também evita recálculos do
    // array de datafields.
    if (/* permitirVisualizacao */ true) {
      dataFields.push({ name: COLUNA_VISUALIZAR, type: "number", map: "id" });
    }

    if (/* permitirEdicao */ true) {
      dataFields.push({ name: COLUNA_EDITAR, type: "number", map: "id" });
    }

    if (/* permitirExclusao */ true) {
      dataFields.push({ name: COLUNA_EXCLUIR, type: "number", map: "id" });
      dataFields.push({
        name: COLUNA_PODE_EXCLUIR,
        type: "boolean",
        map: "permitirExclusao",
      });
      dataFields.push({
        name: COLUNA_PODE_EDITAR,
        type: "boolean",
        map: "permitirEdicao",
      });
    }

    if (/* permitirAlterarModoEdicao */ true) {
      dataFields.push({
        name: COLUNA_MOBILE,
        type: "string",
        map: "modoEdicao",
      });
    }

    dataFields.push({
      name: COLUNA_COMPATIBILIZACAO,
      type: "boolean",
      map: "permitirCompatibilizacao",
    });
    dataFields.push({
      name: COLUNA_CHECKBOX_MULT_SELECAO,
      type: "bool",
    });

    return dataFields;
  };

  // Datafields para a tabela, criado com base nos campos.
  // Não vamos inicializar este state com valor padrão, pois isso abre a
  // possibilidade da tabela ser ordenada por uma coluna que não existe.
  const [dataFields, setDataFields] = useState(createDatafields());

  // Efeito para extrair dataFields dos campos.
  useEffect(() => {
    setDataFields(createDatafields())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    campos, 
    prontoParaExibir,
  ]);
  const validaPermissaoEditar = (entidade) => {
    if (entidade?.permitirEdicao || entidade?.podeEditar) {
      return true;
    }
    return false;
  }
  const validaPermissaoExcluir = (entidade) => {
    if (entidade?.permitirExclusao || entidade?.podeExcluir) {
      return true;
    }
    return false;
  }
  const cellClass = (row, columnfield, value, rowData) => {
    if (!rowDisponivelParaSelecao(rowData) || !validaPermissaoEditar(rowData)|| !validaPermissaoExcluir(rowData)) {
      return row % 2 ? "checkbox-disable-even" : "checkbox-disable-odd";
    }
  }
  const podeSelecionarCheckbox = (rowIndex, datafield, columntype, value) => {
    const displayingRows = jqxRef.current.getdisplayrows();
    let selectedRow = displayingRows.find((value) => { return value.boundindex == rowIndex });
    return rowDisponivelParaSelecao(selectedRow) && validaPermissaoEditar(selectedRow)&& validaPermissaoExcluir(selectedRow);
  };
  const createColumns = () => {
    const novasColunas = [...colunas];
    if(colunaCheckboxGrid){
//cellclassname nao esta na doc de api do jqxgrid, apenas na pg de demo, e ainda sim nao exibe todos os parametros que o callback pode receber
// https://www.jqwidgets.com/react/react-grid/index.htm#https://www.jqwidgets.com/react/react-grid/react-grid-cellsstyling.htm
      const colunaBotaoCheckbox = { text: nomeColunaCheckbox, dataField: COLUNA_CHECKBOX_MULT_SELECAO, columntype: 'checkbox', editable: true, cellbeginedit: podeSelecionarCheckbox,cellclassname:cellClass}
      novasColunas.push(colunaBotaoCheckbox);
    }

    if (permitirCompatibilizacao && !permitirVisualizacao) {
      novasColunas.unshift(
        createControlColumn({
          iconClasses: ["fas", "fa-sync", "text-primary"],
          dataField: COLUNA_COMPATIBILIZACAO,
          ref: jqxRef,
          onClick: compatibilizar,
          botaoAtivo: (entidade) => podeCompatibilizarRef.current(entidade),
          hintBotao: 'Validação da associação do material geológico do afloramento com a amostra'
        })
      );
    }

    if (permitirAlterarModoEdicao) {
      novasColunas.unshift(
        createControlColumn({
          iconClasses: ["fas", "fa-mobile-alt", "text-warning"],
          dataField: COLUNA_MOBILE,
          ref: jqxRef,
          onClick: (id, rowdata) => {
            let idClicado = rowdata?.rowData?.id;
            alterarModoEdicao(idClicado);
          },
          botaoAtivo: (entidade) => podeAlterarModoEdicaoRef.current(entidade),
        })
      );
    }

    if (!isFormOpen && permitirExclusao) {
      novasColunas.unshift(
        createControlColumn({
          iconClasses: ["fas", "fa-trash", "text-danger"],
          dataField: COLUNA_EXCLUIR,
          ref: jqxRef,
          onClick: (id, rowdata) => excluirId.current(id, rowdata),
          botaoAtivo: (entidade) => {
            if(colunaCheckboxGrid){
              return podeExcluirEntidadeRef.current(entidade) && rowDisponivelParaEdicaoOuExclusao(entidade)
            }
          return  podeExcluirEntidadeRef.current(entidade)
          },
        })
      );
    }

    if (!isFormOpen && permitirEdicao) {
      novasColunas.unshift(
        createControlColumn({
          iconClasses: ["fas", "fa-edit", "text-warning"],
          dataField: COLUNA_EDITAR,
          ref: jqxRef,
          onClick: (id, rowData) => editarId.current(id, rowData),
          botaoAtivo: (entidade) => {
            if(colunaCheckboxGrid){
              return podeEditarEntidadeRef.current(entidade) && rowDisponivelParaEdicaoOuExclusao(entidade)
            }
            return podeEditarEntidadeRef.current(entidade)
          },
        })
      );
    }

    
    if (!isFormOpen && permitirGerarPdf) {
      novasColunas.unshift(
        createControlColumn({
          iconClasses: ["fas", "fa-file-pdf", "text-danger"],
          dataField: COLUNA_BAIXAR,
          ref: jqxRef,
          onClick: (id, rowdata)=> {
            const event = new TableActionEvent(id, rowdata);
            onBtnGerarPdfClick(event, tableRef, rowdata);
            if (event.preventedDefault) {
              return;
            }
          },
          botaoAtivo: (entidade) => {

            if (!entidade) return false;

            const { tipo, modoCadastro } = entidade;

            //Verificação para amostras definitivas que tem tipo undefined
            if (!tipo && modoCadastro === "DEFINITIVO")
              return true;
            if (!tipo && modoCadastro === "RASCUNHO")
              return false;

            switch (tipo) {
                case TIPO_ATIVIDADE_RECURSO_MINERAL.id:
                    return false;
                case TIPO_ATIVIDADE_AMOSTRAS.id:
                    return true;
                case TIPO_ATIVIDADE_AFLORAMENTO.id:
                    return modoCadastro === "DEFINITIVO";
                default:
                    //default true para fichas petrograficas
                    return true;
            }
          },
        })
      );
    }

    if (!isFormOpen && permitirVisualizacao) {
      novasColunas.unshift(
        createControlColumn({
          iconClasses: ["fas", "fa-eye", "text-primary"],
          dataField: COLUNA_VISUALIZAR,
          ref: jqxRef,
          onClick: (id, rowData) => visualizarId.current(id, rowData),
          botaoAtivo: (entidade) => podeVisualizarEntidadeRef.current(entidade),
        })
      );
    }

    return novasColunas;
  }

  // Também criamos a coluna desde a primeira vez pelo mesmo motivo que os
  // datafields.
  const [tableColumns, setTableColumns] = useState(createColumns());
  const [selectAll, setSelectAll] = useState(false);
  const [tableColumnsHasRun, setTableColumnsHasRun] = useState(false); // Flag para debug, não é essencial.
  
  // Quando DEBUG é true, usaremos um useEffect modificado que printa um aviso
  // e lista os props que mudaram. Para evitar redefinição de colunas, evite
  // mudar o valor dos props que forem printados.
  // Futuramente, este useEffect deverá ser substituído por um useMemo, que
  // corresponde melhor ao que esta função faz. Mas por enquanto está assim porque
  // eu não tenho uma versão modificada do useMemo.
  const useEffectMod = DEBUG
    ? useEffectDebugger
    : useEffect;
  useEffectMod(() => {
    setTableColumns(createColumns());
    setTableColumnsHasRun(true);
    
  }, [
    colunas,
    permitirEdicao,
    permitirExclusao,
    permitirVisualizacao,
    permitirAlterarModoEdicao,
    permitirCompatibilizacao,
    isFormOpen
  ],
  // Estes parâmetros adicionais são para o caso de chamarmos a versão modificada
  // no useEffect que printa warnings. Isso só acontece quando DEBUG é true, e
  // caso contrário esses parâmetros serão ignorados.
  `Subform ${nome} redefinindo colunas. Isso deve ser evitado por questões de performance. Props que mudaram:`,
  [ // Este array vai ser usado para printar os nomes das dependências. Mantenha igual ao segundo parâmetro.
    "colunas",
    "permitirEdicao",
    "permitirExclusao",
    "permitirVisualizacao",
    "permitirAlterarModoEdicao",
    "permitirCompatibilizacao"
  ],
  !tableColumnsHasRun, // <- Faz pular o warning da primeira vez que o effect roda.
  );

  // Effect para atualizar os elementos da tabela.
  useEffect(() => {
    async function fetchOrdenacao() {
      if (!isFormOpen) {
        setTableItems(await ordenacaoPersonalizada(elementosExibidos));
      }
    }
    fetchOrdenacao();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementosExibidos, dataFields, isFormOpen]);

  useEffect(() => {
    if (isFormOpen) {
      // Esconde os botões do SubForm pai
      if (alternarBotoesPai) {
        const subFormPaiElem = document
          .getElementById(uid)
          .parentElement?.closest(".subform");

        if (subFormPaiElem) {
          const nomeSubFormPai = [...subFormPaiElem.classList]
            .find((c) => c.startsWith("subform-nome-"))
            ?.replace("subform-nome-", "");
          const nomeSubFormPaiTratado = tratarNomeClasse(nomeSubFormPai);
          const btnsSubFormPaiElem = subFormPaiElem.querySelector(
            `.subform-btns-padrao-${nomeSubFormPaiTratado}`
          );

          if (btnsSubFormPaiElem) {
            btnsSubFormPaiElem.style.display = "none";
          }
        }
      }
    } else {
      // Exibe os botões do SubForm pai se não houver outros SubForm filhos abertos
      if (alternarBotoesPai) {
        const subFormPaiElem = document
          .getElementById(uid)
          .parentElement?.closest(".subform");

        if (subFormPaiElem) {
          const nomeSubFormPai = [...subFormPaiElem.classList]
            .find((c) => c.startsWith("subform-nome-"))
            ?.replace("subform-nome-", "");
          const nomeSubFormPaiTratado = tratarNomeClasse(nomeSubFormPai);
          const qtdSubFormFilhosAbertos =
            subFormPaiElem.getElementsByClassName("subform-aberto").length;

          if (qtdSubFormFilhosAbertos === 0) {
            const btnsSubFormPaiElem = subFormPaiElem.querySelector(
              `.subform-btns-padrao-${nomeSubFormPaiTratado}`
            );

            if (btnsSubFormPaiElem) {
              btnsSubFormPaiElem.style.display = "";
            }
          }
        }
      }
    }
  }, [uid, alternarBotoesPai, isFormOpen]);

  const verificaExibirTabelaComFormularioAbertoESemRegistro = useMemo(() => {
    if (modoSubFormComGrid) {
      return !(elementosExibidos && elementosExibidos.length > 0) && !isFormOpen
    }
  
    return isFormOpen ||
    !(
      prontoParaExibir &&
      // Não exibir a tabela se não houverem registros
      (exibirTabelaSemRegistro ||
        (!exibirTabelaSemRegistro &&
          elementosExibidos &&
          elementosExibidos.length > 0))
    )
   
}, [elementosExibidos, exibirTabelaSemRegistro, modoSubFormComGrid, prontoParaExibir, isFormOpen]);
  // **********************************************************************
  // Render

  const renderFormInternal = useCallback(
    (props) => {
      return (
        <SubFormPrefixContext.Provider value={prefixoAtual}>
          <>
            <NotificadorErrosForm />

            <Field name={FORM_ERROR} component={HiddenField} />

            {renderForm({
              formProps: props,
              prefixoNome: "",
              editando: estadoEdicao.estaEditando,
              visualizando: estadoEdicao.estaVisualizando,
            })}

            {exibirBotoesPadrao && (
              <BotoesPadrao
                voltar={onBtnBackClick}
                onBtnVoltar={onBtnVoltar}
                disabled={props.submitting}
                nome={nome}
                uid={uid}
                mostrarBotaoConfirmar={
                  !estadoEdicao.estaVisualizando && exibirBotaoConfirmar
                }
                labelBotaoConfirmar={labelBotaoConfirmar}
                labelBotaoVoltar={labelBotaoVoltar}
                exibirBotoesPadrao={exibirBotoesPadrao}
                onBtnConfirm={onBtnConfirm}
              />
            )}
          </>
        </SubFormPrefixContext.Provider>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      renderForm,
      onBtnBackClick,
      estadoEdicao.estaEditando,
      prefixoAtual,
      nome,
      uid,
      labelBotaoConfirmar,
      labelBotaoVoltar,
      onBtnConfirm,
      exibirBotaoConfirmar,
      exibirBotoesPadrao,
      onBtnVoltar
    ]
  );
const iconCheckbox = `fas text-secondary ${selectAll? "fa-check-square":"fa-square"} ml-1`;
  const tableComponent = (
    <Row>
      {colunaCheckboxGrid &&
        <>
          <Col md="10"></Col>
          <Col md="2" style={{
            paddingBottom: 0,
            marginBottom: "0px !important"
          }} className="float-right">
            <GridAppendButton
              className="float-right mb-0"
              style={{
                fontSize: "smaller", paddingBottom: 0,
                marginBottom: "0px !important", borderRadius: "20px 20px 0px 0px", backgroundColor: "#e8e8e8", color: "black", width: "100%", paddingLeft: "0px", paddingRight: "0px"
              }}
              onClick={() => {
                let rows = jqxRef.current.getdisplayrows();
                const indexExibidos = rows.map((value) => { return value.id; });
                const originaldata = jqxRef?.current?.props?.source?.originaldata;
                const rowsToEdit = originaldata.filter((value) => indexExibidos.includes(value.id) && rowDisponivelParaSelecao(value) &&  validaPermissaoEditar(value)&& validaPermissaoExcluir(value));
                rowsToEdit.forEach((value) => { value[COLUNA_CHECKBOX_MULT_SELECAO] = !selectAll });
                setSelectAll(!selectAll);
                jqxRef.current.updatebounddata('sort');
                aoSelecionarTodosCheckbox(rowsToEdit)
              }}>
              Selecionar todos
              <i class={iconCheckbox} ></i>
            </GridAppendButton>
          </Col>
        </>}
      <Col md="12">
        <Table
        
          name={nome}
          ref={(ref) => {
            if (!_tableRef.current && ref) {
              _tableRef.current = ref;
            }
            if (tableRef && !tableRef.current && ref) {
              tableRef.current = ref;
            }
          }}
          jqxRef={jqxRef}
          editable={colunaCheckboxGrid}
          autoshowloadelement={false}
          columns={tableColumns}
          items={itensTabela ?? tableItems}
          extraSourceParameters={parametrosParaTableSource}
          datafields={dataFields}
          className={`subform-table subform-table-${nome}`}
          hide={verificaExibirTabelaComFormularioAbertoESemRegistro}
          {...tableProps}
        />
      </Col>
    </Row>
  );

  // Renderiza o final-form se o SubForm estiver aberto, caso contrário renderiza o grid
  if (isFormOpen) {
    let initialValues = { ...valoresIniciais };

    if (estadoEdicao.estaEditando) {
      initialValues = {
        ...elementosExibidosPorId[estadoEdicao.id],
      };
    } else {
      if (!isNumber(initialValues.id)) {
        initialValues.id = ULTIMO_ID_TEMPORARIO--;
      }
    }

    return (
      <>
      <div id={uid} className={`subform subform-nome-${nome} subform-aberto`}>
        <Form
          onSubmit={enviarForm}
          initialValues={initialValues}
          validate={validar}
          decorators={decoradores}
          mutators={mutadores}
          subscription={formSubscription}
          keepDirtyOnReinitialize={keepDirtyOnReinitialize}
        >
          {renderFormInternal}
        </Form>
      </div>
      
      {modoSubFormComGrid && (
          <div id={uid} className={`subform subform-nome-${nome} subform-fechado`}>
            {tableComponent}
          </div>
        )}

    </>
    );
  }

  return (
    exibirCardTabela ?
    <Card className="mt-4">
      <Card.Body>
      <div id={uid} className={`subform subform-nome-${nome} subform-fechado`}>
      <Row>
        <Col md={8} className="mb-4">
          {tituloFormMenor ? (
            <Card.Subtitle>
              {tituloForm}
              {exibirAsterisco && <span style={{ color: "red" }}>*</span>}
            </Card.Subtitle>
          ) : (
            <Card.Title>
              {tituloForm}
              {exibirAsterisco && <span style={{ color: "red" }}>*</span>}
            </Card.Title>
          )}
        </Col>
        {exibirBotaoInsercao && (
          <Col md={4}>
            <PrimaryButton
              className={`float-right mb-2 subform-btn-adicionar subform-btn-adicionar-${nome}`}
              onClick={abrirFormularioEmBranco}
              titulo="Adicionar"
              disabled={!permitirInsercao}
              tabIndex={tabIndexAdicionar}
            />
          </Col>
        )}
      </Row>
      {tableComponent}
      
    </div>
      </Card.Body>
    </Card>
    :
    <div id={uid} className={`subform subform-nome-${nome} subform-fechado`}>
      <Row>
        <Col md={8} className="mb-4">
          {tituloFormMenor ? (
            <Card.Subtitle>
              {tituloForm}
              {exibirAsterisco && <span style={{ color: "red" }}>*</span>}
            </Card.Subtitle>
          ) : (
            <Card.Title>
              {tituloForm}
              {exibirAsterisco && <span style={{ color: "red" }}>*</span>}
            </Card.Title>
          )}
        </Col>
        {exibirBotaoInsercao && (
          <Col md={4}>
            <PrimaryButton
              className={`float-right mb-2 subform-btn-adicionar subform-btn-adicionar-${nome}`}
              onClick={abrirFormularioEmBranco}
              titulo="Adicionar"
              disabled={!permitirInsercao}
              tabIndex={tabIndexAdicionar}
            />
          </Col>
        )}
      </Row>
      {tableComponent}
    </div>
  );
}

SubForm.propTypes = {
  // Props básicas
  renderForm: PropTypes.func.isRequired,
  campos: PropTypes.array.isRequired,
  colunas: PropTypes.array.isRequired,
  nome: PropTypes.string.isRequired,

  tableRef: PropTypes.object,

  // Controle de layout/funcionalidades
  keepDirtyOnReinitialize: PropTypes.bool,
  podeExcluirEntidade: PropTypes.func,
  podeEditarEntidade: PropTypes.func,
  podeVisualizarEntidade: PropTypes.func,
  criarDecoradores: PropTypes.func,
  valoresIniciais: PropTypes.object,
  permitirInsercao: PropTypes.bool,
  permitirEdicao: PropTypes.bool,
  permitirExclusao: PropTypes.bool,
  permitirVisualizacao: PropTypes.bool,
  permitirAlterarModoEdicao: PropTypes.bool,
  permitirCompatibilizacao: PropTypes.bool,
  permitirGerarPdf: PropTypes.bool,
  exibirBotaoInsercao: PropTypes.bool,
  exibirBotaoConfirmar: PropTypes.bool,
  delayParaExibicaoTabela: PropTypes.number,
  alternarBotoesPai: PropTypes.bool,
  exibirTabelaSemRegistro: PropTypes.bool,
  scrollAoFechar: PropTypes.bool,
  formSubscription: PropTypes.object, // undefined ou FormState https://final-form.org/docs/final-form/types/FormState | https://codesandbox.io/s/32r824vxy1
  validarVoltar: PropTypes.bool,
  naoValidarCamposVoltar: PropTypes.array,
  labelBotaoConfirmar: PropTypes.string,
  labelBotaoVoltar: PropTypes.string,
  onBtnVoltar: PropTypes.func,
  onBtnAdicionar: PropTypes.func,
  onBtnConfirm: PropTypes.func,
  confirmarExclusao: PropTypes.bool,
  tableProps: PropTypes.object,
  ordenacaoPersonalizada: PropTypes.func,
  validarExclusaoAtividade: PropTypes.func,
  podeCompatibilizar: PropTypes.func,
  compatibilizar: PropTypes.func,

  // Eventos
  onSubmit: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  validar: PropTypes.func,
  onBtnEditClick: PropTypes.func,
  onBtnDeleteClick: PropTypes.func,
  onBtnViewClick: PropTypes.func,
  onBtnGerarPdfClick: PropTypes.func,
  onBtnChangeEditModeClick: PropTypes.func,
  aoCarregarExibidos: PropTypes.func,
  exibirBotaoModoEdicao: PropTypes.func,

  // Estado
  formularioAberto: PropTypes.bool,
};

SubForm.defaultProps = {
  valoresIniciais: {},
  validar: () => { },
  parametrosParaTableSource: {},
  elementos: {
    existentes: [],
    novos: [],
    editadosPorId: {},
    idsExcluidos: [],
  },
  ordenacaoPersonalizada: (elementos) => elementos,
  exibirBotaoInsercao: true,
  exibirBotaoConfirmar: true,
  permitirInsercao: true,
  permitirEdicao: true,
  permitirExclusao: true,
  permitirVisualizacao: false,
  scrollAoFechar: true,
  formSubscription: undefined,
  alternarBotoesPai: true,
  delayParaExibicaoTabela: DELAY_EXIBICAO_TABELA_PADRAO,
  validarVoltar: true,
  naoValidarCamposVoltar: [],
  exibirTabelaSemRegistro: false,
  labelBotaoConfirmar: 'Confirmar',
  labelBotaoVoltar: 'Voltar',
  onBtnVoltar: () => {},
  onBtnConfirm: () => {},
  confirmarExclusao: true,

  formularioAberto: false,
}

export default SubForm;

class TableActionEvent {
  constructor(value, rowdata) {
    this.preventedDefault = false;
    if (value) {
      this.value = value;
      this.rowdata = rowdata;
    }
  }

  preventDefault() {
    this.preventedDefault = true;
  }
}


/// Functions that make functions. This is for using useRef instead of useCallback, to avoid
/// recreating any functions that use these functions as dependencies.

function editarIdFunctionGenerator(onOpen, setFormOpen, onBtnEditClick, setEstadoEdicao, abrirForm, tableRef) {
  return (id, rowdata) => {
    if (onBtnEditClick) {
      const event = new TableActionEvent(id, rowdata);
      onBtnEditClick(event, tableRef.current?.tableRef, rowdata);
      if (event.preventedDefault) {
        return;
      }
    }
    setEstadoEdicao({
      estaEditando: true,
      ehTemporario: id < 0,
      id,
    });

    abrirForm({ setFormOpen, onOpen });
  }
}

function excluirIdFunctionGenerator(elementos, persistirDados, onBtnDeleteClick, confirmarExclusao, tableRef, validarExclusaoAtividade) {
  const func = async (id, rowdata) => {
    id = parseInt(id);
    // Não excluir caso cancelar exclusão no modal
    if (confirmarExclusao) {
      const exclusaoConfirmada = validarExclusaoAtividade ? await validarExclusaoAtividade(id, rowdata, tableRef) : await swalConfirmarExclusao();
      if (!exclusaoConfirmada) return id;
    }

    if (onBtnDeleteClick) {
      const event = new TableActionEvent(id, rowdata);
      onBtnDeleteClick(event, tableRef.current?.tableRef, rowdata);
      if (event.preventedDefault) {
        return id;
      }
    }

    let novosElementos, alteracoesExtras, elementoExcluido;

    if (id < 0) {
      // excluindo um registro novo. é só tirar de registros :)
      const novosRegistrosSemOExcluido = elementos.novos.filter((r) => {
        return null == r || r.id !== id;
      });

      elementoExcluido = elementos.novos.find((r) => id === r?.id);

      novosElementos = {
        ...elementos,
        novos: novosRegistrosSemOExcluido,
      };

      let idxDoExcluido = -1;
      for (let i = 0; i < elementos.novos.length; i++) {
        if (null !== elementos.novos[i] && elementos.novos[i].id === id) {
          idxDoExcluido = i;
          break;
        }
      }
      if (idxDoExcluido > -1) {
        const novosRegistrosComOExcluidoNulificado = [...elementos.novos];
        // TODO porque seta nulo e não remove da lista? Se remover vai quebrar algo do sistema?
        novosRegistrosComOExcluidoNulificado[idxDoExcluido] = null;
        alteracoesExtras = {
          novos: novosRegistrosComOExcluidoNulificado,
        };
      }
    } else {
      // excluindo um de verdade
      elementoExcluido = elementos?.existentes?.find((r) => id === r.id);
      novosElementos = {
        ...(elementos ?? {}),
        idsExcluidos: [...(elementos.idsExcluidos ?? []), Number(id)],
      };
    }

    await persistirDados(novosElementos, alteracoesExtras, {
      elementoExcluido,
    }).catch(
      (err) =>
        DEBUG &&
        console.error(
          "Erro durante exclusão de registro de subformulário:",
          err
        )
    );
  }
  return func;
}

function visualizarIdFunctionGenerator(onOpen, setFormOpen, onBtnViewClick, setEstadoEdicao, abrirForm, tableRef) {
  return (id, rowdata) => {
      if (onBtnViewClick) {
        const event = new TableActionEvent(id, rowdata);
        onBtnViewClick(event, tableRef.current?.tableRef, rowdata);
        if (event.preventedDefault) {
          return;
        }
      }

      setEstadoEdicao({
        estaEditando: true, // Necessário ser true para carregamento do elemento. Como o botão salvar é escondido, não há problemas
        estaVisualizando: true, // Pode ser utilizado para desabilitar campos através do parâm. "visualizado" enviado ao renderForm
        ehTemporario: id < 0,
        id,
      });

      abrirForm({ setFormOpen, onOpen });
    }
}

// Utility functions just to print which items changed.
// Adapted from https://stackoverflow.com/a/59843241
const usePrevious = (value, initialValue) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
const useEffectDebugger = (effectHook, dependencies, warningMessage, dependencyNames, skipWarning = false) => {
  const previousDeps = usePrevious(dependencies, []);

  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index;
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency
        }
      };
    }

    return accum;
  }, {});

  if (Object.keys(changedDeps).length && !skipWarning) {
    console.warn(warningMessage, changedDeps);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectHook, dependencies);
};

/**
 * This is for the following props: podeEditarEntidade, podeExcluirEntidade,
 * podeVisualizarEntidade, podeAlterarModoEdicao.
 *
 * If the input is a function, returns the input. If the input is any other
 * defined value, returns a function that always retuns that value. If the input
 * is undefined, return the second parameter.
 */
function wrapPermission(f, whenUndefined) {
  if (typeof f === "function") {
    return f;
  } else if (typeof f !== "undefined") {
    return () => f;
  } else {
    return whenUndefined;
  }
}
