import React, { useCallback, useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import { Table, createControlColumn } from "../../components/Jqx/Table";
import PageScaffold from "../../components/scaffold/PageScaffold";
import Service, { BASEGEO, VALIDACAO } from "../../service/Service";
import "../../assets/css/validacao.css";

import BlockUi from "react-block-ui";
import { Col, Form, Row } from "react-bootstrap";
import aflora_tabela from '../../assets/img/aflora_tabela.png';
import amostra_tabela from '../../assets/img/amostra_tabela.png';
import recmin_tabela from '../../assets/img/recmin_tabela.png';
import petrografia_tabela from '../../assets/img/petrografia_tabela.png';
import Card from "../../components/card";
import InfoPanelGPS from "../../components/infoPanelGPS";
import AppMapComponent from "../../components/mapa/map_component/AppMapComponent";
import { configMapaValidacao } from "../../config/mapa";
import LegendaMapa from "../estacoes/components/mapa/LegendaMapa";
import { DropdownListWithValue } from "../../components/Jqx/DropdownList";
import Hint from "../../components/hint/Hint";
import GridAppendButton from "../../components/Jqx/Button/GridAppendButton";
import { FocusableButton } from "../../components/Jqx/Button";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import { mostrarNotificacao } from "../../reducers/notificacaoReducer";

import { geoJSONParaArcGIS } from "../../util/mapa"
import { AprovacaoAtividade } from "./components/atividade/AprovacaoAtividade/AprovacaoAtividade";
// eslint-disable-next-line no-unused-vars
import { TIPO_ATIVIDADE_AFLORAMENTO, TIPO_ATIVIDADE_AMOSTRAS, TIPO_ATIVIDADE_PETROGRAFIA, TIPO_ATIVIDADE_RECURSO_MINERAL } from "../../util/constantes";
import { PendenciasModal } from "./components/PendenciasModal";
import { datafieldsAtividades } from "./DataFieldsAtividadesValidacao";

const Validacao = (props) => {
  /**
 * @type {React.MutableRefObject<Table>}
 */
  const tableRef = useRef(null);
  const dispatch = useDispatch();

  const [listaAtividades, setListaAtividades] = useState([]);
  const [mapaRef, setMapaRef] = useState(null);
  const [localidadeStorage, setLocalidadeStorage] = useState(null);
  const [isRevisorProjeto, setIsRevisorProjeto] = useState(false);
  const [isValidadorProjeto, setIsValidadorProjeto] = useState(false);
  const [idSubAreaSelecionada, setIdSubAreaSelecionada] = useState(null);
  const [listaSubAreas, setListaSubAreas] = useState([]);
  const [selectAllValidacao, setSelectAllValidacao] = useState(false);
  const [rowsSelecionadasValidacao, setRowsSelecionadasValidacao] = useState([]);
  const [rowsSelecionadasRevisao, setRowsSelecionadasRevisao] = useState([]);
  const [mostrarPendencias, setMostrarPendencias] = useState(false);
  const [idLinhaSelecionada, setIdLinhaSelecionada] = useState(null);
  const [layerAtividadesValidacao, setLayerAtividadesValidacao] = useState(null);

  const [exibirModal, setExibirModal] = useState(false);
  const [atividadeAprovacao, setAtividadeAprovacao] = useState({});
  const [atualizarTabelaAtividades, setAtualizarTabelaAtividades] = useState(false);
  const [estacoes, setEstacoes] = useState([]);

  const estacoesService = Service("/estacoes");
  const projetosService = Service("/projetos");

  const iconCheckboxValidacao = `fas text-secondary ${selectAllValidacao ? "fa-check-square" : "fa-square"} `;
  const COLUNA_VISUALIZAR = "idVisualizar";
  const COLUNA_CHECKBOX_REVISADO = "checkboxRevisado";
  const COLUNA_CHECKBOX_VALIDADO = "checkboxValidado";


  const rowDisponivelParaSelecao = (rowData, columnfield) => {
    if (columnfield === COLUNA_CHECKBOX_VALIDADO) {
      return (isValidadorProjeto && (!rowData.validado && rowData.revisado && rowData.podeValidar));
    }
    return false;

  }

  const buscarGeoJsonArea =  (idArea)=>{
    const geoJSONArea =  estacoesService.customURL(
      "GET",
      `areas/${idArea}/geometria`)
      return geoJSONArea
  }

  const cellbegineditListControl = (rowIndex, datafield, columntype, value) => {
    const displayingRows = tableRef.current.getdisplayrows();
    let selectedRow = displayingRows.find((value) => { return value.boundindex === rowIndex });
    const podeEditar = rowDisponivelParaSelecao(selectedRow, datafield);
    const id = selectedRow?.id;
    if (podeEditar) {
      if (datafield === COLUNA_CHECKBOX_VALIDADO) {
        const jaEstaSelecionada = rowsSelecionadasValidacao.find((item) => item.id == selectedRow.id);
        if (!jaEstaSelecionada) {
          setRowsSelecionadasValidacao([...rowsSelecionadasValidacao, selectedRow])
        }
        retornaPaginasEItensSelecionados(tableRef, displayingRows, id);
        return true;
      }
      if (datafield === COLUNA_CHECKBOX_REVISADO) {
        const jaEstaSelecionada = rowsSelecionadasRevisao.find((item) => item.id == selectedRow.id);
        if (!jaEstaSelecionada) {
          setRowsSelecionadasRevisao([...rowsSelecionadasRevisao, selectedRow])
        }
        retornaPaginasEItensSelecionados(tableRef, displayingRows, id);
        return true;
      }
    }
    retornaPaginasEItensSelecionados(tableRef, displayingRows, id);
    return false;
  }



  const retornaPaginasEItensSelecionados = (referenciaTabela, displayingRows, id) => {
    if (referenciaTabela?.current) {
      //Pega o tamanho da paginação
      const rowsPerPage = referenciaTabela.current.getpaginginformation()?.pagesize
      // Obtém os dados de todas as linhas no grid
      const rowData = referenciaTabela?.current?.getrows();
      // Encontra o índice da linha com base no ID
      const rowIndex = rowData?.findIndex(row => row.id === id);
      if (rowIndex < 0) {
        console.log('Linha não encontrada')
      } else {
        // Calcula o índice da página usando o índice da linha e o número de linhas por página
        const pageIndex = Math.floor(rowIndex / rowsPerPage);
        if (pageIndex) {

          referenciaTabela.current.gotopage(pageIndex);
        }
        referenciaTabela.current.clearselection();
        const row_grid1 = referenciaTabela.current
          .getrows()
          .find((r) => r.id === id);
        if (row_grid1) {
          const boundIndex = row_grid1.boundindex;
          referenciaTabela.current.selectrow(boundIndex);
        }
      }
    }

    displayingRows.forEach((row, index) => {
      const updatedRow = {
        ...row
      };
      tableRef.current.updaterow(index, updatedRow);
    });
  }

  const cellClassChefeProjeto = (row, columnfield, value, rowData) => {
    return row % 2 ? "checkbox-disable-even" : "checkbox-disable-odd";
  }

  const cellClassGerenteDivisao = (row, columnfield, value, rowData) => {
    if (!rowDisponivelParaSelecao(rowData, columnfield)) {
      return row % 2 ? "checkbox-disable-even" : "checkbox-disable-odd";
    }
  }

  const [mapaCarregado, setMapaCarregado] = useState(false);

  const onMapaCarregado = async () => {

    if (!localidadeStorage || !listaSubAreas)
      return;

    setMapaCarregado(true);
    setLayerAtividadesValidacao(mapaRef.props.config.state.otherLayers[0]);

    // Geralmente o projeto/area/subarea carregam e são selecionados automaticamente antes do mapa,
    // então quando o mapa carregar, utilizamos os valores atuais para filtragem
    if (!mapaRef)
      return;

    let idProjeto = localidadeStorage.idProjeto;
    
    const subareaSelecionada = listaSubAreas.find(subarea => subarea.id === idSubAreaSelecionada);
    
    if (subareaSelecionada?.id === -1) {
      const idArea = JSON.parse(sessionStorage.getItem("localidade")).idArea;
      const geoJSONArea = await buscarGeoJsonArea(idArea)
      const geometryFilter = geoJSONParaArcGIS(geoJSONArea);
      mapaRef.limparPontos(true);
      mapaRef.filtrar(
        idProjeto,
        geometryFilter
      );
    }else{
      const geometryFilter = geoJSONParaArcGIS(subareaSelecionada?.geoJSON);
      mapaRef.limparPontos(true);
      mapaRef.filtrar(
        idProjeto,
        geometryFilter
      );
    }
    filtrarAtividadesProjeto();
  }

  const filtrarAtividadesProjeto = () => {
    let idProjeto = localidadeStorage.idProjeto;
    let layerAtividades = mapaRef.props.config.state.otherLayers[0];
    let campoIdProjeto = layerAtividades.projectIdField;
    let url = layerAtividades.url;

    let query = campoIdProjeto + " = " + idProjeto;
    mapaRef.filtrarOutro(url, query);
  }

  const mostrarModalAprovacao = (id, data) => {
    const displayingRows = tableRef.current.getdisplayrows();
    setAtividadeAprovacao(data.rowData);
    setExibirModal(true);
    retornaPaginasEItensSelecionados(tableRef, displayingRows, id)
  }

  const ModalAprovacao = useCallback(() => {
    if (exibirModal !== null && exibirModal === true && atividadeAprovacao !== null) {
      return <AprovacaoAtividade
        exibirModal={exibirModal}
        setExibirModal={aoFecharModalAprovacao}
        setAtualizarTabelaAtividades={setAtualizarTabelaAtividades}
        isRevisorProjeto={isRevisorProjeto}
        isValidadorProjeto={isValidadorProjeto}
        atividadeSelecionada={atividadeAprovacao} />
    }
    return <></>
  }, [exibirModal, atividadeAprovacao]);

  const ModalPendencias = useCallback(() => {
    if (mostrarPendencias !== null && mostrarPendencias === true) {
      return <PendenciasModal
        setExibirModal={setMostrarPendencias}
        titulo={"Pendências no Projeto"}
        tamanho="xl"
        aberto={mostrarPendencias === true}
        aoFechar={() => {
          setMostrarPendencias(false);
        }}

      />
    }
    return <></>
  }, [mostrarPendencias]);

  const aoFecharModalAprovacao = (value) => {
    const displayingRows = tableRef.current.getdisplayrows();
    const rowSelecionada = tableRef.current.getselectedrowindexes();
    let selectedRowId = displayingRows.find((row, index) => {
      return index === rowSelecionada[0];
    })?.id;
    setExibirModal(value);
    setTimeout(() => {
      retornaPaginasEItensSelecionados(tableRef, displayingRows, selectedRowId)
    }, 100);

  }

  const criaIconeAtividade = (value) => {
    let tela = "";
    let titulo = "";
    let imagem = null;
    switch (value) {
      case TIPO_ATIVIDADE_AMOSTRAS.id:
        titulo = "Cadastros de  Amostras";
        imagem = amostra_tabela;
        break;
      case TIPO_ATIVIDADE_AFLORAMENTO.id:
        titulo = "Cadastros de Afloramentos";
        imagem = aflora_tabela;
        break;
      case TIPO_ATIVIDADE_RECURSO_MINERAL.id:
        titulo = "Cadastros de Recursos Minerais";
        imagem = recmin_tabela;
        break;
      case TIPO_ATIVIDADE_PETROGRAFIA.id:
        titulo = "Cadastros de análise petrográfica";
        imagem = petrografia_tabela;
        break;
      default:
        break;
    }
    tela = `<img style="height: 30px;width: 30px;" title="${titulo}" src="${imagem}" />`
    return `<div class=" d-flex justify-content-center align-items-center " style="margin-top: 2px;">${tela}</div>`;
  }

  const criaHintNumeroCampo = (rowdata, value) => {
    const responsavelTecnico = "Técnico responsável: " + rowdata.responsavelTecnico;
    return `<div title="${responsavelTecnico}" class=" d-flex justify-content-center align-items-center " style="margin-top: 8px;">${value}</div>`;
  }

  const colunasAtividades = [
    createControlColumn({
      iconClasses: ["fas", "fa-eye", "text-primary"],
      dataField: COLUNA_VISUALIZAR,
      ref: tableRef,
      onClick: mostrarModalAprovacao,
      botaoAtivo: () => true,
    }),
    { text: "Subárea", datafield: "subArea", width: "12%", editable: false },
    {
      text: "Atividade", datafield: "tipo", width: "8%", editable: false,
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        return criaIconeAtividade(value);
      }
    },
    {
      text: "Número de campo/amostra", datafield: "numeroCampo", width: "15%", editable: false,
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        return criaHintNumeroCampo(rowdata, value);
      },
      renderer: () => {
        return '<div style="margin-top: 3px; margin-left: 5px;">Número de campo/<br />amostra</div>'
      }
    },
    { text: "Descrição", datafield: "resumo", width: "19%", editable: false },
    {
      text: "Revisor", datafield: COLUNA_CHECKBOX_REVISADO, width: "8%", columntype: 'checkbox', cellclassname: cellClassChefeProjeto, editable: false,
    },
    {
      text: "Validador", datafield: COLUNA_CHECKBOX_VALIDADO, width: "8%", columntype: 'checkbox', cellclassname: cellClassGerenteDivisao, cellbeginedit: cellbegineditListControl, editable: true,
    },
    {
      text: "Estação", datafield: "codigoEstacao", width: "8%", editable: false,
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        return `<div title="${value}" class=" d-flex justify-content-center align-items-center " style="margin-top: 8px;">${value}</div>`;
      
      }
    },
    {
      text: "Status da revisão", datafield: "statusValidacao", width: "17%", editable: false, cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        let corTexto = "#FFF";
        const emptyDivValue = `<div class="jqx-grid-cell-left-align" margin-top: 8.5px;"></div>`
        if (rowdata.modoCadastro == "RASCUNHO" || rowdata.modoCadastro == "TEMPORARIO") {
          return emptyDivValue;
        }
        const colorMap = {
          "Aguardando revisão": "#BF5700",
          "Aguardando validação": "#BF5700",
          "Correção solicitada": "#FF4B4B",
          "Corrigido": "#00A7FF",
          "Validado": "#8ABC7D",
          "Atividade modificada": "#9B80FF"
        };
        return `<div class="jqx-grid-cell-left-align" style="color: ${colorMap[value] || corTexto}; margin-top: 8.5px;">${value || emptyDivValue}</div>`
      },

    },

  ];

  const atualizaAtividadesParaValidacao = async () => {
    if (idSubAreaSelecionada === null || localidadeStorage === null || !idSubAreaSelecionada || !localidadeStorage) {
      return;
    }

    if (idSubAreaSelecionada === -1) {
      const idProjeto = JSON.parse(sessionStorage.getItem("localidade")).idProjeto;
      const idArea = JSON.parse(sessionStorage.getItem("localidade")).idArea;
      const respostaAtividades = await Service(
        `/atividades/disponiveisTodasValidacaoERevisao/${idProjeto}`,
        VALIDACAO
      ).get(idArea);

      const atividades = respostaAtividades.data;
      setListaAtividades(atividades);
    } else {
      const respostaAtividades = await Service(
        `/atividades/disponiveisValidacaoERevisao`,
        VALIDACAO
      ).get(idSubAreaSelecionada);

      const atividades = respostaAtividades.data;
      setListaAtividades(atividades);
    }
  }

  const atualizaListaSubAreas = async () => {
    if (!localidadeStorage)
      return;
    const idProjeto = localidadeStorage.idProjeto;
    const respostaSubAreas = await projetosService.customURL(
      "GET",
      `/${idProjeto}/subAreas`
    );

    // Ordena a lista de subáreas por ordem alfabética
    let subAreaOrdenada = respostaSubAreas.data.dados.sort((a, b) => {
      if (a.nome < b.nome) {
        return -1;
      }
      if (a.nome > b.nome) {
        return 1;
      }
      return 0;
    });
    const subAreas = subAreaOrdenada;

    // Verifica se "Todas" já está na lista
    const todasIndex = subAreas.findIndex((subArea) => subArea.nome === "Todas");
    if (todasIndex === -1) {
      subAreas.unshift({ id: -1, nome: "Todas" });
    }
    setListaSubAreas(subAreas);
  }
  const buscarEstacoesDaSubArear = async () => {
    const idArea = JSON.parse(sessionStorage.getItem("localidade")).idArea;

    if (idSubAreaSelecionada !== null) {
      const respostaEstacoesSubAreas = await estacoesService.customURL(
        "GET",
        `${idSubAreaSelecionada > 0 ? `?idSubArea=${idSubAreaSelecionada}` : `?idArea=${idArea}`}`
      );
      setEstacoes(respostaEstacoesSubAreas);
    }
    else {
      setEstacoes([])
    }

  }

  const carregaValoresStorage = () => {
    const storage = JSON.parse(sessionStorage.getItem("localidade"));
    setLocalidadeStorage(storage);
    setIdSubAreaSelecionada(-1);
    setIsRevisorProjeto(storage.revisorProjeto);
    setIsValidadorProjeto(storage.validadorProjeto);
  }

  const btnVerificarPendencias = (e) => {
    e.preventDefault();
    setMostrarPendencias(!mostrarPendencias);
  };

  const onFiltrar = () => {
    if (!mapaRef)
      return;
    mapaRef.limparPontos();
  }

  const onSelecaoOutrosMudar = (selectedLayerId, id, selectedRow) => {
    if (!selectedRow || selectedRow === null)
      return;
    setIdLinhaSelecionada(id);
    if (selectedRow.id_status_validacao_publicacao === null) {
      dispatch(
        mostrarNotificacao('Atividade não liberada para Revisão.', { tipo: 'info' }, true)
      );
      return;
    }


    if (tableRef?.current) {

      //Pega o tamanho da paginação
      const rowsPerPage = tableRef.current.getpaginginformation()?.pagesize
      // Obtém os dados de todas as linhas no grid
      const rowData = tableRef?.current?.getrows();
      // Encontra o índice da linha com base no ID
      const rowIndex = rowData?.findIndex(row => row.id === id);
      if (rowIndex < 0)
        return;

      // Calcula o índice da página usando o índice da linha e o número de linhas por página
      const pageIndex = Math.floor(rowIndex / rowsPerPage);
      if (pageIndex) {

        tableRef.current.gotopage(pageIndex);
      }
      tableRef.current.clearselection();
      const row_grid1 = tableRef.current
        .getrows()
        .find((r) => r.id === id);
      if (row_grid1) {
        const boundIndex = row_grid1.boundindex;
        tableRef.current.selectrow(boundIndex);
      }
    }
  }

  const selecionarLinhaTabela = (evt) => {
    const displayingRows = tableRef.current.getdisplayrows();
    const id = evt.args.row?.id;
    const urlMapaAtividades = layerAtividadesValidacao.url;
    if (!id || id === idLinhaSelecionada) return 0;
    if (evt.type === "rowselect") {
      if (mapaCarregado) {
        setIdLinhaSelecionada(id);
        mapaRef.selecionarOutro(urlMapaAtividades, id);
      }

    }
    retornaPaginasEItensSelecionados(tableRef, displayingRows, id);
  }

  const iconesLegendas = [
    {
      "descricao": "Em Preenchimento",
      "cor": "rgba(155,155,155,100)"
    },
    {
      "descricao": "Aguardando Revisão",
      "cor": "rgba(255,153,0,100)"
    },
    {
      "descricao": "Aguardando Validação",
      "cor": "rgba(180,95,6,100)"
    },
    {
      "descricao": "Validado",
      "cor": "rgba(56,118,29,100)"
    },
    {
      "descricao": "Correção Solicitada",
      "cor": "rgba(255,0,0,100)"
    },
    {
      "descricao": "Corrigido",
      "cor": "rgba(0,0,255,100)"
    },
    {
      "descricao": "Atividade Modificada",
      "cor": "rgba(153,0,255,100)"
    }
  ]


  useEffect(() => {

    carregaValoresStorage();
  }, []);
  useEffect(() => {
    buscarEstacoesDaSubArear()
    atualizaListaSubAreas();
    atualizaAtividadesParaValidacao();
    setRowsSelecionadasValidacao([]);
    setRowsSelecionadasRevisao([]);
    setSelectAllValidacao(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSubAreaSelecionada]);

  useEffect(() => {

    if (atualizarTabelaAtividades === false)
      return;
    atualizaAtividadesParaValidacao();
    setAtualizarTabelaAtividades(false);
  }, [atualizarTabelaAtividades]);

  const validarAtividadesMarcadas = useCallback(async () => {
    if (tableRef.current === null)
      return;
    const displayingRows = tableRef.current.getdisplayrows();
    const rowsMarcadasRevisao = displayingRows.filter((item) => item[COLUNA_CHECKBOX_REVISADO] && rowDisponivelParaSelecao(item, COLUNA_CHECKBOX_REVISADO));
    const rowsMarcadasValidacao = displayingRows.filter((item) => item[COLUNA_CHECKBOX_VALIDADO] && rowDisponivelParaSelecao(item, COLUNA_CHECKBOX_VALIDADO));
    const rowsMarcadasParaEnvio = rowsMarcadasRevisao.concat(rowsMarcadasValidacao);

    const qtdItensSelecionados = rowsMarcadasParaEnvio.length;
    if (qtdItensSelecionados <= 0) {
      return
    }
    const result = await swal({
      title: `Enviar ${qtdItensSelecionados} atividade(s) para publicação?`,

      icon: "info",
      buttons: {
        cancel: "Não, cancelar",
        confirm: { text: "Sim, desejo prosseguir!", className: "btn-success" },
      },
    });

    if (!result) {
      return
    };

    const payloadRevisao = { idsRevisao: rowsMarcadasRevisao.map(item => item.id), idsValidacao: rowsMarcadasValidacao.map(item => item.id) };
    try {
      await Service('/atividades/envioValidacao', VALIDACAO).post(payloadRevisao);

    } catch (error) {
      const errorResponse = error?.response?.data;
      await swal({
        title: `Erro`,
        text: errorResponse?.erros?.[0],
        icon: "error",
      });
    }
    atualizaAtividadesParaValidacao();
    setRowsSelecionadasRevisao([]);
    setRowsSelecionadasValidacao([]);
    setSelectAllValidacao(false);
  }, [isValidadorProjeto]);

  const BotaoValidar = useCallback(() => {
    if (isValidadorProjeto)
      return <FocusableButton
        className="float-right m-1 mt-2 "
        style={{ fontSize: "smaller" }}
        onClick={() => validarAtividadesMarcadas()}>
        Validar
      </FocusableButton>

    return <></>
  }, [isValidadorProjeto]);

  return (
    <BlockUi>
      <PageScaffold titulo="Validação de atividades" disableInfo>
        <Container>
          <InfoPanelGPS hideSubArea />
          <Card>
            <Card.Body>
              <Row>
                <Col md={4} className="mb-5">
                  <Form.Label className="small">
                    Subárea
                    <Hint textoAjuda="Informe a subárea do projeto" />
                  </Form.Label>
                  <DropdownListWithValue
                    elementos={listaSubAreas}
                    onSelect={setIdSubAreaSelecionada}
                    value={idSubAreaSelecionada}
                  />
                </Col>
                <hr />
              </Row>

              <>
                <Row>
                  <Col md="12">
                    <LegendaMapa
                      exibirEstacao={false}
                      outrosElementos={iconesLegendas}
                    />

                  </Col>
                </Row>
                <Row>
                  <Col md="8">
                    <Container className="container-grid-atividades-revisao" >
                      <Row>
                        <Col md="4" style={{
                          paddingBottom: 0,
                          paddingLeft: "15px",
                          marginBottom: "0px !important"
                        }} className="float-right">
                          <GridAppendButton
                            className="float-right mb-0 check-revisao-validacao"
                          >
                            <span style={{ marginLeft: "5px" }}>
                              Número de estações: {estacoes?.data?.dados?.length}
                            </span>
                          </GridAppendButton>
                        </Col>
                        <Col md="4"></Col>
                        {isValidadorProjeto ? (<Col md="4" style={{
                          paddingBottom: 0,
                          paddingLeft: 0,
                          marginBottom: "0px !important"
                        }} className="float-right">
                          <GridAppendButton
                            className="float-right mb-0 check-revisao-validacao"
                            onClick={() => {
                              let rows = tableRef.current.getdisplayrows();
                              const indexExibidos = rows.map((value) => { return value.id; });
                              const rowsToEdit = tableRef?.current?.props?.source?.originaldata.filter((value) => indexExibidos.includes(value.id) && rowDisponivelParaSelecao(value, COLUNA_CHECKBOX_VALIDADO));
                              rowsToEdit.forEach((value) => { value[COLUNA_CHECKBOX_VALIDADO] = !selectAllValidacao });
                              setSelectAllValidacao(!selectAllValidacao);
                              tableRef.current.updatebounddata('sort');
                              setRowsSelecionadasValidacao(rowsToEdit)

                            }}>
                            <i className={iconCheckboxValidacao} style={{ marginRight: "15px" }} ></i>
                            <span style={{ marginLeft: "5px" }}>
                              Selecionar todos para validação
                            </span>
                          </GridAppendButton>
                        </Col>) : <Col md="4"></Col>}
                        <Col md="12">
                          <Table
                            jqxRef={tableRef}
                            datafields={datafieldsAtividades}
                            columns={colunasAtividades}
                            onRowSelectChanged={selecionarLinhaTabela}
                            items={listaAtividades}
                            editable
                          />
                        </Col>
                      </Row>
                      <Row>

                        <Col md="6" className="mt-3">
                          <FocusableButton
                            className="float-left m-2"
                            onClick={() => {
                              props.history.goBack();
                            }}
                          >
                            Voltar
                          </FocusableButton>
                        </Col>
                        <Col md="6" className="mt-3">
                          <BotaoValidar />
                          <FocusableButton
                            className="float-right m-2"
                            style={{ fontSize: "smaller" }}
                            onClick={btnVerificarPendencias}
                          >Verificar Pendências</FocusableButton>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col md="4">
                    <AppMapComponent
                      ref={(instance) => {
                        setMapaRef(instance);
                      }}
                      config={configMapaValidacao}
                      onCarregarMapa={onMapaCarregado}
                      onFiltrar={onFiltrar}
                      debugMensagens={!!process.env.REACT_APP_MAP_DEBUG}
                      onSelecaoOutrosMudar={onSelecaoOutrosMudar}
                    />
                  </Col>
                </Row>
              </>
            </Card.Body>
          </Card>
          <hr />
        </Container>
      </PageScaffold>
      <ModalAprovacao />
      <ModalPendencias />
    </BlockUi>
  );
}

export default Validacao
