import React, { useCallback, useEffect, useState, useRef } from "react";
import Card from "../../components/card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  COLUNA_ADICIONAR,
  COLUNA_BAIXAR,
  COLUNA_EXCLUIR,
  COLUNA_EXIBIR,
} from "../../util/constantes";
import DropdownListWithValue from "../../components/Jqx/DropdownList/DropdownListWithValue";
import Petrografias from "./components/petrografia/Petrografias";
import Geocronologias from "./components/geocronologia/Geocronologias";
import { Table, createCheckboxColumn, createControlColumn } from "../../components/Jqx/Table";
import { TIPO_ATIVIDADE_PETROGRAFIA, TIPO_ATIVIDADE_GEOCRONOLOGIA } from "./utils/contantes/constantesComuns";
import {  CLASSES_AMOSTRA_PETROGRAFIA, CLASSE_MINERIO, CLASSE_OUTROS, CLASSE_ROCHA, CLASSE_SOLO, IGNEA, METAMORFICA, SEDIMENTAR } from "./utils/contantes/constantesPetrografia";
import {  CLASSES_AMOSTRA_GEOCRONOLOGIA } from "./utils/contantes/constantesGeocronologia";

import Service, { AFLORA, AMOSTRAS, BASEGEO, PETRO, VALIDACAO } from "../../service/Service";
import dayjs from "dayjs";
import { buscarIdUsuario } from "../../util/authenticacaoUtils";
import { swalConfirmarExclusaoParaDadosAnaliticos, swalErro } from "../../util/validadores";
import { createValidationColumn, validacaoRowDisponivelParaSelecao, validacaoRowDisponivelParaEdicao } from "../validacao/utils/validationGridUtils";
import { FocusableButton, PrimaryButton } from "../../components/Jqx/Button";
import swal from "sweetalert";
import BlockUi from "react-block-ui";
import {
  consultarGeocronologiasPorIdAmostra  
} from "./components/geocronologia/services/geocrologiaServices";

const TabelasDadosAnaliticos = ({
  idEstacao,
  idProjetoCorrente,
  idProjetoHistorico,
  projetos,
  nomeProjeto,
  render,
  BotoesVoltarSalvar = () => null,
  amostras = null,
  isAmostraHistorica = null,
  idAmostraSelecionada = null,
  visitas=[]
}) => {
  const [idTipoAtividadeSelecionada, setIdTipoAtividadeSelecionada] = useState(null);
  const [idAmostra, setIdAmostra] = useState(idAmostraSelecionada);
  const [amostraSelecionada, setAmostraSelecionada] = useState();
  const [listaAtividades, setListaAtividades] = useState([]);
  const [tiposAtividadesFiltrada, setTiposAtividadesFiltrada] = useState([]);
  const [atividadesNaoFiltradas, setAtividadesNaoFiltradas] = useState([]);
  const [exibirBotoes, setExibirBotoes] = useState(true);
  const [formularioAberto, setFormularioAberto] = useState(false);
  const [podeInserirPetrografia, setPodeInserirPetrografia] = useState(true);
  const [podeInserirGeocronologia, setPodeInserirGeocronologia] = useState(true);
  const [logicaJaRealizada, setLogicaJaRealizada] = useState(false);
  const [mostraErroFichaPetrografica, setMostraErroFichaPetrografica] = useState();
  const [fichaPetrografica, setFichaPetrografica] = useState();
  const [bloquearFichaPetrografica, setBloquearFichaPetrografica] = useState(false);
  const [projetoAreaSubarea, setProjetoAreaSubarea] = useState();
  const [area, setArea] = useState();
  const [subArea, setSubArea] = useState();
  const [dataAnalise, setDataAnalise] = useState();
  const [nomeAmostra, setNomeAmostra] = useState();
  const [idUsuarioAtual, setIdUsuarioAtual] = useState();
  const [coletores, setColetores] = useState();
  const [areas, setAreas] = useState();
  const [subAreas, setSubAreas] = useState();
  const [rochaAfloramento, setRochaAfloramento] = useState();
  const [permitirEdicao, setPermitirEdicao] = useState(false);
  const [permitirExclusao, setPermitirExclusao] = useState(false);
  const [permitirVisualizacao, setPermitirVisualizacao] = useState(false);
  const [permitirGerarPdf, setPermitirGerarPdf] = useState(false);
  const [isChefeProjeto, setIsChefeProjeto] = useState(false);
  const [todasAtividadesPertencemUsuarioLogado, setTodasAtividadesPertencemUsuarioLogado] = useState(false);
  const [todasAtividadesGeocronologiaPertencemUsuarioLogado, setTodasAtividadesGeocronologiaPertencemUsuarioLogado] = useState(false);
  const [quantidadeFichas, setQuantidadeFichas] = useState([]);
  const [temAfloramento, setTemAfloramento] = useState(false);
  const [afloramentoAssociadoAmostra, setAfloramentoAssociadoAmostra] = useState([]);
  const [carregamentoGlobal, setCarregamentoGlobal] = useState(true);
  const [geocronologias, setGeocronologias] = useState([])

  const jqxGrid = useRef(null);
  const obterDadosAmostra = async (idAmostra) => {
    try {
      const response = await Service('/cadastroAmostra/amostra', AMOSTRAS).get(idAmostra);
      const { data: { dados: atividade = {} } } = response;
      setAmostraSelecionada(atividade);

      return atividade;
    } catch (error) {
      console.error('Erro ao obter os dados da amostra:', error);
      throw error;
    }
  };

  const obterColetoresProjetos = useCallback(
    async (idProjeto) => {
      try {
        const response = await Service(`/coletores/paraProjeto/${idProjeto}`).query();
        const { data: { dados: coletores = [] } } = response;
        return coletores;
      } catch (error) {
        console.error('Erro ao obter coletores:', error);
        throw error;
      }
    },
    []
  );

  const obterAtividades = useCallback(
    async (valor) => {
      try {
        const response = await Service(`/dados-analiticos-amostras/${valor}`, BASEGEO)
        .get("atividades")
        .catch((_error) => {
          swalErro({ title: "Erro ao consultar as atividades relacionadas" });
        });

        return response?.data;
      } catch (error) {
        console.error('Erro ao obter atividades:', error);
        throw error;
      }
    },
    []
  );

  const obterGeocronologias = useCallback(
    async (valor) => {
      return consultarGeocronologiasPorIdAmostra(valor)
    },[])

  const obterFichas = useCallback(
    async (valor) => {
      try {
        const response = await Service(`/petrografias/amostra`, PETRO)
        .get(valor)
        .catch((_error) => {
          swalErro({ title: "Erro ao consultar as fichas petrográficas." });
        });
        return response?.data?.dados;
      } catch (error) {
        console.error('Erro ao obter fichas:', error);
        throw error;
      }
    },
    []
  );

  const obterArea = useCallback(
    async (idProjeto) => {
      try {
        const response = await Service(`/projetos/${idProjeto}/areas`, BASEGEO).query()
        const { data: { dados: areas = [] } } = response;
        return areas;
      } catch (error) {
        console.error('Erro ao obter areas:', error);
        throw error;
      }
    },
    []
  );

  const obterSubArea = useCallback(
    async (idProjeto) => {
      try {
        const response = await Service(`/projetos/${idProjeto}/subAreas`, BASEGEO).query();
        const { data: { dados: subAreas = [] } } = response;
        return subAreas;
      } catch (error) {
        console.error('Erro ao obter subareas:', error);
        throw error;
      }
    },
    []
  );

  const getIdTipoRocha = useCallback(async (idMaterialGeologico) => {
    try {
      const response = await getRochasAfloramento(idMaterialGeologico);
      setRochaAfloramento(response?.data?.dados)
      const idTipoRocha = response?.data?.dados?.idTipoRocha
      return idTipoRocha;
    } catch (error) {
      console.error('Erro ao obter atividade:', error);
      throw error;
    }
  }, []);

  const getRochasAfloramento = async (idMaterialGeologico) => {
    var res = await Service('/rochas-afloramento', AFLORA).get(idMaterialGeologico)
    return res
  }

  const verificarRochasDoAfloramentoAssociado = useCallback(
    async (amostra) => {
      try {
        const associacaoAfloramento = amostra?.associacoes?.find(x => x.tipoAtividade === "AFLORAMENTO");

        if (associacaoAfloramento) {
          const idMaterialGeologico = associacaoAfloramento.idMaterialGeologico;
          const idTipoRocha = await getIdTipoRocha(idMaterialGeologico);

          const temIgnea = idTipoRocha === IGNEA;
          const temMetamorfica = idTipoRocha === METAMORFICA;
          const temSedimentar = idTipoRocha === SEDIMENTAR;

          if ((temIgnea || temMetamorfica) && !temSedimentar) {
            return "IGNEAS_METAMORFICAS";
          } else if (!temIgnea && !temMetamorfica && temSedimentar) {
            return "SEDIMENTARES";
          } else {
            return "";
          }
        }
      } catch (error) {
        console.error('Erro ao identificar tipo da rocha do material geológico:', error);
      }
      return "";
    },
    [getIdTipoRocha]
  );

  const getIdProjeto = useCallback(() => {
    let idProjeto = JSON.parse(sessionStorage.getItem("localidade")).idProjeto;
    return idProjeto
  }, []);


  const montarAtividades= (idClasseAmostra)=> {
    const permiteCadastroPetrografia = CLASSES_AMOSTRA_PETROGRAFIA.some(({ id }) => id === idClasseAmostra);  
    const permiteCadastroGeocronológico = CLASSES_AMOSTRA_GEOCRONOLOGIA.some(({ id }) => id === idClasseAmostra)

    if(!permiteCadastroGeocronológico && !permiteCadastroPetrografia)
      return []
    
   return (permiteCadastroPetrografia && permiteCadastroPetrografia) 
   ? [TIPO_ATIVIDADE_PETROGRAFIA, TIPO_ATIVIDADE_GEOCRONOLOGIA] :  
   permiteCadastroPetrografia ? [TIPO_ATIVIDADE_PETROGRAFIA] : [TIPO_ATIVIDADE_GEOCRONOLOGIA] ;
  }
  
  const setarAtividades=(atividades = [])=>{
    const geocronologias = atividades.filter((atividade) => atividade.tipo === 'GEOCRONOLOGIA')
    const geocronFinal = geocronologias.length ? [{...geocronologias[0]}] : []
    setGeocronologias(geocronologias)
    const finalAtividadesArray = []
    const naoGeocronologias = atividades.filter((atividade) => atividade.tipo !== 'GEOCRONOLOGIA')

    if(geocronFinal.length)
      finalAtividadesArray.push(geocronFinal[0])

    if(naoGeocronologias.length)
      finalAtividadesArray.push(...naoGeocronologias)
 
    setListaAtividades(finalAtividadesArray);
  }

  const onSelectAmostra = useCallback(
    async (valor) => {
      setIdAmostra(valor);
      if (valor) {
        setCarregamentoGlobal(true);
        const amostraEncontrada = amostras?.find(({ id }) => id === valor);
        if (!amostraEncontrada) return; // Adiciona um guard clause
  
        const { idClasseAmostra, nomeAmostra } = amostraEncontrada;
        const atividadesFiltradas = montarAtividades(idClasseAmostra)
        setAtividadesNaoFiltradas(atividadesFiltradas);
        setNomeAmostra(nomeAmostra);
  
        const idProjeto = getIdProjeto();
        const localidade = JSON.parse(sessionStorage.getItem("localidade"));
        const { nomeProjeto, nomeArea, nomeSubArea, idArea, idSubArea } = localidade || {};
        const projetoAreaSubarea = `${nomeProjeto}/${nomeArea}/${nomeSubArea}`;
        
        setArea(idArea);
        setSubArea(idSubArea);
        setProjetoAreaSubarea(projetoAreaSubarea);
  
        const dataFormatada = dayjs(new Date()).format("DD/MM/YYYY");
        const idUsuarioAtual = buscarIdUsuario(idProjeto);
  
        setIdUsuarioAtual(idUsuarioAtual);
        setDataAnalise(dataFormatada);
  
        const projeto = projetos.find((p) => p.id === idProjeto);
        setIsChefeProjeto(projeto?.revisor);
  
        const [
          coletores,
          areas,
          subAreas,
          atividades,
          fichas,
          amostra,
        ] = await Promise.all([
          obterColetoresProjetos(idProjeto),
          obterArea(idProjeto),
          obterSubArea(idProjeto),
          obterAtividades(valor),
          obterFichas(valor),
          obterDadosAmostra(valor),
        ]).catch(()=> setCarregamentoGlobal(false));
  
        setColetores(coletores);
        setAreas(areas);
        setSubAreas(subAreas);
        setarAtividades(atividades)
  
        setQuantidadeFichas(fichas?.length || 0);
        if (fichas) {
          const todasFichasPertencemUsuario = fichas.every(item => item.idResponsavel === idUsuarioAtual);
          setTodasAtividadesPertencemUsuarioLogado(todasFichasPertencemUsuario);
        }
    
        definirModoEdicao(fichas);
  
        const listaDeTipos = atividades?.map(atividade => atividade.tipo) || [];
        const objetosFiltrados = atividadesFiltradas.filter(objeto => !listaDeTipos.includes(objeto.id));

        setTiposAtividadesFiltrada(objetosFiltrados);
  
        const afloramentoAssociadoAmostra = amostra?.associacoes?.find(x => x.tipoAtividade === "AFLORAMENTO");
        setAfloramentoAssociadoAmostra(afloramentoAssociadoAmostra);
        setTemAfloramento(!!afloramentoAssociadoAmostra);
  
        switch (idClasseAmostra) {
          case CLASSE_ROCHA.id:
            setFichaPetrografica(await verificarRochasDoAfloramentoAssociado(amostra));
            break;
          case CLASSE_SOLO.id:
            setFichaPetrografica("SEDIMENTARES");
            setBloquearFichaPetrografica(true);
            break;
          case CLASSE_MINERIO.id:
          case CLASSE_OUTROS.id:
            setFichaPetrografica("");
            break;
          default:
            setFichaPetrografica("");
            break;
        }
      } else {
        setTiposAtividadesFiltrada([]);
        setIdTipoAtividadeSelecionada(null);
      }
  
      setCarregamentoGlobal(false);
    },
    [amostras, getIdProjeto, projetos, obterColetoresProjetos, obterArea, obterSubArea, verificarRochasDoAfloramentoAssociado]
  );
  

  const atualizarAtividadesPetrografias = useCallback(async() => {
    if(!idAmostra)
      return;

    let buscaDadosAnaliticos = Service(`/dados-analiticos-amostras/${idAmostra}`, BASEGEO)
                                  .get("atividades")
                                  .catch((_error) => {
                                    swalErro({ title: "Erro ao consultar as atividades relacionadas" });
                                  });

    let buscaPetrografiasAmostra = Service(`/petrografias/amostra`, PETRO)
                                      .get(idAmostra)
                                      .catch((_error) => {
                                        swalErro({ title: "Erro ao consultar as fichas petrográficas." });
                                      });
    Promise.all([
      buscaDadosAnaliticos,
      buscaPetrografiasAmostra,
    ]).then(([
      atividadesResponse,
      petrografiasResponse,
    ]) => {
      const fichas = petrografiasResponse?.data?.dados;
      setQuantidadeFichas(fichas?.length);
      if (fichas) {
        const todasFichasPertencemUsuario = fichas.every(item => item.idResponsavel === idUsuarioAtual);
        if (todasFichasPertencemUsuario) {
          setTodasAtividadesPertencemUsuarioLogado(true)
        } else {
          setTodasAtividadesPertencemUsuarioLogado(false)
        }
      }   

      definirModoEdicao(fichas);
      setarAtividades(atividadesResponse?.data);
      var listaDeAtividades = atividadesResponse?.data

      const listaDeTipos = [];
      for (let i = 0; i < listaDeAtividades?.length; i++) {
        listaDeTipos.push(listaDeAtividades[i].tipo);
      }
    });
  },[idAmostra, idUsuarioAtual]);

  const alterarTipoAtividadeSelecionada = useCallback(
    async (idTipoAtividade) => {
      setIdTipoAtividadeSelecionada(idTipoAtividade)
      setPermitirEdicao(false)
      setPermitirExclusao(false)
      setPermitirVisualizacao(false)
      setPermitirGerarPdf(false)
    },
    []
  );

  useEffect(() => {
    if (!logicaJaRealizada && amostras && amostras.length > 0) {
      onSelectAmostra(idAmostra)
      setLogicaJaRealizada(true)
    }
  }, [onSelectAmostra, idAmostra, logicaJaRealizada, amostras]);

  const tabelaAtividadesRef = React.createRef();

  const inserirMetodoVisualizacao = (row, column, value, rowData) => {
    var tipo = column?.rowData?.tipo
    setIdTipoAtividadeSelecionada(tipo)
    setPodeInserirPetrografia(false)
    setPodeInserirGeocronologia(false)
    setPermitirEdicao(false)
    setPermitirVisualizacao(true)
    setPermitirGerarPdf(false)
  }
  

  const inserirMetodoEdicao = (row, column, value, rowData) => {
    var tipo = column?.rowData?.tipo
    setIdTipoAtividadeSelecionada(tipo)
    setPermitirEdicao(true)
    setPermitirGerarPdf(false)
    setPodeInserirPetrografia(true)  
    setPodeInserirGeocronologia(true)
    setPermitirVisualizacao(false)
    setPermitirExclusao(false)
  }

  const inserirMetodoExclusaoGeocron = (column) => {
    var tipo = column?.rowData?.tipo
    setIdTipoAtividadeSelecionada(tipo)
    setPermitirEdicao(false)
    setPermitirGerarPdf(false)
    setPodeInserirPetrografia(false) 
    setPodeInserirGeocronologia(false) 
    setPermitirVisualizacao(false)
    setPermitirExclusao(true)
  }

  const definirModoEdicao = (fichas) => {
    setPodeInserirGeocronologia(true)
    if(fichas && fichas.length > 0){
      return;
    }
    setPermitirEdicao(true);
    setPermitirGerarPdf(false)
    setPodeInserirPetrografia(true)
  }

  const excluirAtividade = async (row, column, value, rowData) => {    
    if (column.rowData.tipo.includes("GEOCRONOLOGIA")) {
      return inserirMetodoExclusaoGeocron(column)
    }

      
    const exclusaoConfirmada = await swalConfirmarExclusaoParaDadosAnaliticos();

    if (exclusaoConfirmada) {
      let idAtividade;
      let idAmostra;
      try {
        idAtividade = column?.rowData?.id;
        idAmostra = amostraSelecionada?.id;

        //Exclui a atividade
        await Service("/atividades", BASEGEO)
          .delete(idAtividade)
          .catch((_error) => {
            swalErro({ title: "Erro ao excluir a atividade " });
            throw new Error("Erro ao excluir a atividade");
          });
      
        //Exclui as fichas
        await Service("/petrografias/deletar-petrografias", PETRO)
          .delete(idAmostra)
          .catch((_error) => {
            swalErro({ title: "Erro ao excluir as fichas petrográficas" });
            throw new Error("Erro ao excluir as fichas petrográficas");
          });      
    } 
      catch (error) {
        console.log(error)
      }
      //Atualiza lista de atividades   
      setIdAmostra(idAmostra)
      onSelectAmostra(idAmostra)
      setPermitirGerarPdf(false)
    }
  }

  const redirecionaGerarPdf = async (row, column, value, rowData) => {
    var tipo = column?.rowData?.tipo
    setIdTipoAtividadeSelecionada(tipo)
    setPodeInserirPetrografia(false)
    setPodeInserirGeocronologia(false)
    setPermitirVisualizacao(false)
    setPermitirEdicao(false)
    setPermitirGerarPdf(true)
  }
  const verificaPodeExcluir = (rowData) => {
    //Pode excluir caso seja o chefe do projeto ou todas as atividades sejam do usuario logado
    const podeEditarStatusValidacao = validacaoRowDisponivelParaEdicao(rowData);
    if (podeEditarStatusValidacao === false)
      return false;

    if (isChefeProjeto) return true
    if (todasAtividadesPertencemUsuarioLogado) return true
    return false;
  }

  const verificaPodeEditar = (rowData) => {
    const podeEditarStatusValidacao = validacaoRowDisponivelParaEdicao(rowData);
    if (podeEditarStatusValidacao === false)
      return false;

    return true;
  }

  const cellClass = (row, columnfield, value, rowData) => {    
    if (!validacaoRowDisponivelParaSelecao(rowData)) {
      return row % 2 ? "checkbox-disable-even" : "checkbox-disable-odd";
    }
  }

  const cellbegineditListControl = (rowIndex, datafield, columntype, value) => {   
    const displayingRows = jqxGrid.current.getdisplayrows();
    let selectedRow = displayingRows.find((value) => { return value.boundindex === rowIndex });
    if(selectedRow.tipo.includes('GEOCRONOLOGIA'))
      return false
    const podeEditar = validacaoRowDisponivelParaSelecao(selectedRow, datafield);
    if (podeEditar && (isChefeProjeto || todasAtividadesPertencemUsuarioLogado)) {
      if (datafield === "podeEnviarParaRevisao") {
        return true;
      }      
    }
    return false;
  }

  const montarGeocronologiaGrid = (value)=>{
    return geocronologias.length ? value + ` (${geocronologias.length})` : value
  }

  const tabelaAtividadesColumns = [
    createControlColumn({
      iconClasses: ["fas", "fa-eye", "text-primary"],
      dataField: COLUNA_EXIBIR,
      ref: tabelaAtividadesRef,
      onClick: inserirMetodoVisualizacao,
      botaoAtivo: () => true
    }),
    createControlColumn({
      iconClasses: ["fas", "fa-edit", "text-warning"],
      dataField: COLUNA_ADICIONAR,
      ref: tabelaAtividadesRef,
      onClick: inserirMetodoEdicao,
      botaoAtivo: (rowData) => verificaPodeEditar(rowData)
    }),
    createControlColumn({
      iconClasses: ["fas", "fa-trash", "text-danger"],
      dataField: COLUNA_EXCLUIR,
      ref: tabelaAtividadesRef,
      onClick: excluirAtividade,
      botaoAtivo: (rowData) => verificaPodeExcluir(rowData),
    }),
    createControlColumn({
      iconClasses: ["fas", "fa-file-pdf", "text-danger"],
      dataField: COLUNA_BAIXAR,
      ref: tabelaAtividadesRef,
      onClick: redirecionaGerarPdf,
      botaoAtivo:(rowData) => !rowData.tipo.includes("GEOCRONOLOGIA"),
    }),
    {
      text: "Tipo", datafield: "tipo", width: "27%", align: 'left', editable: false,
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        let resultado;
        if (quantidadeFichas > 0 && rowdata.tipo !== 'GEOCRONOLOGIA') {
          resultado = `${value} (${quantidadeFichas})`;
        }
        else if (rowdata.tipo === 'GEOCRONOLOGIA')
        {
          resultado = montarGeocronologiaGrid(value)
        }
        else {
          resultado = `${value}`;
        }
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8px;">${resultado}</div>`;

      }
    },
    { text: "Descrição", datafield: "nomeTipo", editable: false, width: "39%", align: 'left' },
    {
      text: "Status da revisão", datafield: "statusValidacao", editable: false, width: "14%", editable: false, createwidget: createValidationColumn({ ref: tabelaAtividadesRef, method: "createwidget", hint: "Visualizar detalhes para correção"}),
      initwidget: createValidationColumn({ ref: tabelaAtividadesRef, method: "initwidget", hint: "Visualizar detalhes para correção"}),
    },
    createCheckboxColumn({
      text: "Disponibilizar para revisão",
      dataField: "podeEnviarParaRevisao",
      editable: true,
      cellClass: cellClass,
      beginEdit: cellbegineditListControl,      
    }),
  ];


  const tabelaAtividadesDataField = [
    { name: "id", type: "number", map: "id" },
    { name: "nomeTipo", type: "number", map: "nomeTipo" },
    { name: "tipo", type: "number", map: "tipo" },
    { name: "idStatusValidacao", type: "number", map: "idStatusValidacao" },
    { name: "statusValidacao", type: "String", map: "statusValidacao" },
    { name: "comentarioValidacao", type: "string", map: "comentarioValidacao" },
    { name: "podeEnviarParaRevisao", type: "String", map: "podeEnviarParaRevisao" }

  ];

  const handlePetrografiasMount = (formularioAberto) => {
    setFormularioAberto(formularioAberto)
  };

  const calcularValoresIniciais = () => {
    return {
      'projetoAreaSubarea': projetoAreaSubarea,
      'fichaPetrografica': fichaPetrografica,
      'dataAnalise': dataAnalise,
      'nomeAmostra': nomeAmostra,
      'idResponsavel': idUsuarioAtual,
      'idProjetoCorrente': idProjetoCorrente,
      'idProjetoHistorico': idProjetoHistorico,
      'idArea': area,
      'idSubArea': subArea
    };
  };

  const handleButtonClick = () => {
    setIdTipoAtividadeSelecionada(null);
    setFormularioAberto(false);
    setLogicaJaRealizada(false);
  };
  const BotoesVoltar = () => (
    <Row className="pr-3 subform-btns-padrao subform-btns-padrao-estacao">
      <Col className="mr-2">
        <PrimaryButton
          className="float-right m-1 mr-2 subform-btn-voltar subform-btn-voltar-estacao"
          onClick={() => handleButtonClick()}
          titulo="Voltar"
        />
      </Col>
    </Row>
  );

  return (
    <BlockUi blocking={carregamentoGlobal}>
      <>
        <Card className="card-mb">
          <Card.Body>
            <Card.Title>Registrar nova atividade</Card.Title>
            <p style={{ fontSize: 11 }}>
              Apenas amostras já salvas podem ser associadas as atividades
            </p>
            {(true) && (
              <Row className="my-3">
                <Col>
                  <DropdownListWithValue
                    isClearable={true}
                    opcaoNula={false}
                    elementos={amostras}
                    displayMember={"nomeAmostra"}
                    placeholder="Selecione uma amostra..."
                    onSelect={onSelectAmostra}
                    value={idAmostra}
                    autoFocus
                    disabled={formularioAberto || isAmostraHistorica}
                  />
                </Col>
                <Col>
                  {formularioAberto && (
                    <DropdownListWithValue
                      opcaoNula={false}
                      readOnly={false}
                      elementos={atividadesNaoFiltradas}
                      placeholder="Qual módulo de registro de dados deseja acessar?"
                      onSelect={alterarTipoAtividadeSelecionada}
                      value={idTipoAtividadeSelecionada}
                      forcePlaceholderColor={true}
                      disabled={formularioAberto}
                    />
                  )}
                  {!formularioAberto && (
                    <DropdownListWithValue
                      opcaoNula={false}
                      readOnly={false}
                      elementos={tiposAtividadesFiltrada}
                      placeholder="Qual módulo de registro de dados deseja acessar?"
                      onSelect={alterarTipoAtividadeSelecionada}
                      value={idTipoAtividadeSelecionada}
                      forcePlaceholderColor={true}
                      disabled={formularioAberto}
                    />
                  )}

                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>


        {(idAmostra && !idTipoAtividadeSelecionada) && (
          <Card className="card-mb">
            <Card.Body>
              <Table
                ref={tabelaAtividadesRef}
                jqxRef={jqxGrid}
                columns={tabelaAtividadesColumns}
                items={listaAtividades}
                datafields={tabelaAtividadesDataField}
                pageable={false}
                editable             
              />
            </Card.Body>
          </Card>
        )}


        {idTipoAtividadeSelecionada === "PETROGRAFIA" && (
          <Petrografias
            referenciaForm={render}
            idAmostra={idAmostra}
            idEstacao={idEstacao}
            area={area}
            subArea={subArea}
            nomeProjeto={nomeProjeto}
            atividades={listaAtividades}
            amostras={amostras}
            onMount={handlePetrografiasMount}
            valoresIniciais={calcularValoresIniciais()}
            mostraErroFichaPetrografica={mostraErroFichaPetrografica}
            coletores={coletores}
            projetos={projetos}
            areas={areas}
            subAreas={subAreas}
            isChefeProjeto={isChefeProjeto}
            bloquearFichaPetrografica={bloquearFichaPetrografica}
            amostraSelecionada={amostraSelecionada}
            rochaAfloramento={rochaAfloramento}
            permitirVisualizacao={permitirVisualizacao}
            permitirGerarPdf={permitirGerarPdf}
            permitirEdicao={permitirEdicao}
            permitirInsercao={podeInserirPetrografia}
            amostraRelacionadaComAfloramento={temAfloramento}
            afloramentoAssociadoAmostra={afloramentoAssociadoAmostra}
          />
        )}

       {idTipoAtividadeSelecionada && idTipoAtividadeSelecionada.includes("GEOCRONOLOGIA") && (
          <Geocronologias
            referenciaForm={render}
            idAmostra={idAmostra}
            idEstacao={idEstacao}
            area={area}
            subArea={subArea}
            nomeProjeto={nomeProjeto}
            atividades={listaAtividades}
            amostras={amostras}
            onMount={handlePetrografiasMount}
            coletores={coletores}
            projetos={projetos}
            areas={areas}
            subAreas={subAreas}
            isChefeProjeto={isChefeProjeto}
            amostraSelecionada={amostraSelecionada}
            rochaAfloramento={rochaAfloramento}
            permitirVisualizacao={permitirVisualizacao}
            permitirGerarPdf={permitirGerarPdf}
            permitirEdicao={permitirEdicao}
            permitirInsercao={podeInserirGeocronologia}   
            idUsuarioAtual={idUsuarioAtual}
            permitirExclusao={permitirExclusao}
            amostraOriginal={nomeAmostra}
          />
        )}
        <Row className="mt-2">
          <Col md={12}>
            {!formularioAberto && (
              <FocusableButton
                className="float-right m-1 "
                style={{ fontSize: "smaller" }}
                onClick={async () => {
                  const displayRows = jqxGrid.current.getdisplayrows();
                                    const rowsMarcadasParaEnvio = displayRows.filter((item) => item.podeEnviarParaRevisao && validacaoRowDisponivelParaSelecao(item));
                  const qtdItensSelecionados = rowsMarcadasParaEnvio.length;
                  if (qtdItensSelecionados <= 0) {
                    await swal({
                      title: `É preciso selecionar ao menos uma atividade para revisão`,
                      icon: "info",
                    });
                    return
                  }
                  const result = await swal({
                    title: `Enviar ${qtdItensSelecionados} atividade(s) para revisão? Edição e exclusão ficarão indisponíveis após o envio`,
                    icon: "info",
                    buttons: {
                      cancel: "Não, cancelar",
                      confirm: { text: "Sim, desejo prosseguir!", className: "btn-success" },
                    },
                  });

                  if (!result) return;

                  const payloadRevisao = { idsSolicitados: rowsMarcadasParaEnvio.map(item => item.id) };
                  try {
                    await Service('/atividades/enviaParaRevisao', VALIDACAO).post(payloadRevisao).then(() => {
                      atualizarAtividadesPetrografias();
                    });
                  } catch (error) {
                    const errorResponse = error?.response?.data;
                    await swal({
                      title: `Erro`,
                      text: errorResponse?.erros?.[0],
                      icon: "error",
                    });
                  }
                }}>
                Enviar para revisão
              </FocusableButton>
            )}

            {(exibirBotoes && !formularioAberto) &&
              <BotoesVoltarSalvar exibirBotaoSalvar={false} />
            }
            {formularioAberto&& (
              <BotoesVoltar/>
            )}
          </Col>
        </Row>
      </>
    </BlockUi>
  );
};

export default TabelasDadosAnaliticos;