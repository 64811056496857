import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Field } from "react-final-form";
import { aoEnviarSubForm } from "../../../util/mutadores";
import Card from "../../../components/card";
import { SubForm } from "../../../components/SubForm";
import { DropdownListField } from "../../../components/field/DropdownListField";
import { CounterField } from "../../../components/field/CounterField";
import { campoObrigatorioComMsgGenerica } from "../../../util/validadores";
import { TextAreaField } from "../../../components/field/TextAreaField";
import { getPropsPermissao } from "../../../components/SubForm/SubForm";

const AlteracaoHidrotermal = ({
    form,
    tiposAlteracaoHidrotermal,
    intensidadesAlteracaoHidrotermal,
    disabled
}) => {

    const onSubmit = aoEnviarSubForm(form);

    function ordenacaoCrescente(valor) {        
        return valor.sort((a, b) => a.hierarquia - b.hierarquia);
    }

    const colunasAlteracaoHidrotermal = [
        {
            text: 'Intensidade de alteração',
            datafield: 'idIntensidadeAlteracao',
            width: '30%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const intensidadeAlteracaoId = parseInt(rowdata?.idIntensidadeAlteracao, 10);
                const objetoFiltrado = intensidadesAlteracaoHidrotermal.find(elemento => elemento.id === intensidadeAlteracaoId);
                const valor = objetoFiltrado ? objetoFiltrado.nome : '';
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }
        },
        {
            text: 'Tipo de alteração',
            datafield: 'idTipoAlteracao',
            width: '30%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                console.log(rowdata, ": Rowdata")
                const idTipoAlteracao = parseInt(rowdata?.idTipoAlteracao, 10);
                const objetoFiltrado = tiposAlteracaoHidrotermal.find(elemento => elemento.id === idTipoAlteracao);
                const valor = objetoFiltrado ? objetoFiltrado.nome : '';
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }
        },
        {
            text: 'Hierarquia',
            datafield: 'hierarquia',
            width: '30%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${value}</div>`;
            },

        },
    ]
    const camposAlteracaoHidrotermal = [
        { name: "idIntensidadeAlteracao", type: "string", map: "idIntensidadeAlteracao" },
        { name: "idTipoAlteracao", type: "string", map: "idTipoAlteracao" },
        { name: "hierarquia", type: "number", map: "hierarquia" },
    ];

    return (
        <>
            <div style={{ marginTop: '2.5rem' }}>
                <Card className="mt-3">
                    <Card.Body>
                        <Card.Title>Alteração hidrotermal</Card.Title>
                        <Field name={'alteracaoHidrotermal'} subscription={{ value: true }}>
                            {({ input: { value: alteracaoHidrotermal = [] } }) => (
                                <SubForm
                                    ordenacaoPersonalizada={async (elementos) => await ordenacaoCrescente(elementos)}
                                    nome={'alteracaoHidrotermal'}
                                    onSubmit={onSubmit}
                                    exibirTabelaSemRegistro={false}
                                    modoSubFormComGrid
                                    colunas={colunasAlteracaoHidrotermal}
                                    elementos={alteracaoHidrotermal}
                                    campos={camposAlteracaoHidrotermal}
                                    {...getPropsPermissao(!disabled)}
                                    renderForm={() => (
                                        <>
                                            <Row className="my-3" >
                                                <Col md={6}>
                                                    <Field
                                                        component={DropdownListField}
                                                        name={`idIntensidadeAlteracao`}
                                                        label="Intensidade da alteração"
                                                        dica="Selecione o grau de intensidade da alteração hidrotermal observada na rocha"
                                                        elementos={intensidadesAlteracaoHidrotermal}
                                                        required
                                                        validate={campoObrigatorioComMsgGenerica("Intensidade da alteração")}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="my-3">

                                                <Col md={6}>
                                                    <Field
                                                        name={`idTipoAlteracao`}
                                                        subscription={{ value: true }}>
                                                        {({ input: { value: tipoAlteracao } }) => {

                                                            let listaFiltrada = tiposAlteracaoHidrotermal.filter((t) => t.atual === 'S');

                                                            if (tipoAlteracao && tiposAlteracaoHidrotermal.length && !listaFiltrada.find((t) => t.id === tipoAlteracao)) {
                                                                const item = tiposAlteracaoHidrotermal.find((t) => t.id === tipoAlteracao);

                                                                if (item && item.atual === 'N') {
                                                                    listaFiltrada.push(item);
                                                                    listaFiltrada = listaFiltrada.sort((a, b) => a.nome.localeCompare(b.nome))
                                                                }
                                                            }

                                                            return <Field
                                                                component={DropdownListField}
                                                                name={`idTipoAlteracao`}
                                                                label="Tipo de alteração"
                                                                dica="Selecione o tipo de alteração hidrotermal observado na rocha"
                                                                elementos={listaFiltrada}
                                                            />
                                                        }}
                                                    </Field>
                                                </Col>
                                                <Col md={6}>
                                                    <Field
                                                        component={CounterField}
                                                        name={`hierarquia`}
                                                        label="Hierarquia"
                                                        dica="Selecione a hierarquia da alteração em relação aos processos geológicos observados neste afloramento"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="my-3">
                                                <Col md={12}>
                                                    <Field
                                                        component={TextAreaField}
                                                        name={'informacoesRelevantes'}
                                                        label="Informações relevantes"
                                                        dica="Descreva/relacione outras informações relevantes relacionadas aos processos de alteração hidrotermal."
                                                        maxLength={2000}
                                                        disabled={disabled}
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                />
                            )}
                        </Field>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

export default AlteracaoHidrotermal;