import Petrografias from "../../../../dadosAnaliticos/components/petrografia/Petrografias";
import { useState, useCallback, useEffect } from "react";
import Service, { BASEGEO, AMOSTRAS, AFLORA } from "../../../../../service/Service";
import { decoradorSirgas2000, decoradorMapa } from "../../../../../util/decoradores/decoradorMapa";
import { resetarLatLongInvalidaAoTrocarHemisferio } from "../../../../../util/decoradores/decoradorSeletorCoordenadas";
import { Form } from "react-final-form";
import { setValue } from "../../../../../util/mutadores";

const PetrografiasAprovacao = ({ amostra }) => {
    //#region Bibliotecas
    const [listaAtividades, setListaAtividades] = useState([]);
    const [amostras, setAmostras] = useState([]);
    const [areas, setAreas] = useState([]);
    const [subAreas, setSubareas] = useState([]);
    const [projetos, setProjetos] = useState([]);
    const [amostraSelecionada, setAmostraSelecionada] = useState(null);
    const [coletores, setColetores] = useState([]);

    //#endregion
    const [rochasAfloramento, setRochasAfloramento] = useState(null);
    const [isPossuiAssociacaoAmostra, setIsPossuiAssociacaoAmostra] = useState();

    const [afloramentoAssociadoAmostra, setAfloramentoAssociadoAmostra] = useState(null);
    const [temAfloramento, setTemAfloramento] = useState(null);

    const [isChefeProjeto, setIsChefeProjeto] = useState(null);

    //#region valores iniciais
    //#endregion

    const [idEstacao,] = useState(amostra.idEstacao);
    const [idAmostra,] = useState(amostra.idAmostra);
    const [nomeProjeto,] = useState(amostra.nomeProjeto);
    const [idProjeto, ] = useState(amostra.idProjeto);
    const [idArea,] = useState(amostra.idArea);
    const [idSubArea,] = useState(amostra.idSubarea);

    const decoradores = [decoradorSirgas2000(amostra.idVisita), decoradorMapa(), resetarLatLongInvalidaAoTrocarHemisferio()];

    //#endregion

    const calcularValoresIniciais = () => {
        return {
          'nomeAmostra': amostra.numeroAmostra
        };
      };

    useEffect(() => {
        if (!idProjeto)
            return;

        const listaBibliotecas = [
            coletores,
            listaAtividades,
            amostras,
            areas,
            subAreas,
            projetos,
            amostraSelecionada
        ];

        if (!listaBibliotecas.some(biblioteca => !biblioteca)) {
            return;
        }

        Promise.all(
            [
                Service(`/coletores/paraProjeto/${idProjeto}`).query(),
                Service(`/dados-analiticos-amostras/${idAmostra}/atividades`, BASEGEO).query(),
                Service(`/amostras?idArea=${idArea}&idSubArea=${idSubArea}`).query(),
                Service(`/projetos/${idProjeto}/areas`, BASEGEO).query(),
                Service(`/projetos/${idProjeto}/subAreas`, BASEGEO).query(),
                Service('/projetos', BASEGEO).query(),
                Service('/cadastroAmostra/amostra', AMOSTRAS).get(idAmostra)
            ]
        ).then(([
            coletoresProjetoResponse,
            atividadesAmostraResponse,
            amostrasResponse,
            areasResponse,
            subareasResponse,
            projetosResponse,
            amostraPetrografiaResponse,
        ]) => {
            setColetores(coletoresProjetoResponse.data.dados);
            setListaAtividades(atividadesAmostraResponse.data);
            setAmostras(amostrasResponse.data.dados)
            setAreas(areasResponse.data.dados);
            setSubareas(subareasResponse.data.dados);
            setProjetos(projetosResponse.data.dados);
            setAmostraSelecionada(amostraPetrografiaResponse.data.dados);
        });
    }, [idProjeto]);

    useEffect(() => {
        if(rochasAfloramento || amostraSelecionada === null)
            return;
        const associacaoAfloramento = amostraSelecionada?.associacoes?.find(x => x.tipoAtividade === "AFLORAMENTO");
        if (associacaoAfloramento) {
            setIsPossuiAssociacaoAmostra(true);
            const idMaterialGeologico = associacaoAfloramento.idMaterialGeologico;
            Service('/rochas-afloramento', AFLORA).get(idMaterialGeologico).then((response) =>{
                const rochaAfloramento = response.data.dados;
                setRochasAfloramento(rochaAfloramento);
            });

            setAfloramentoAssociadoAmostra(associacaoAfloramento);
            setTemAfloramento(associacaoAfloramento);
            return;
        }
        setTemAfloramento(null);
        setIsPossuiAssociacaoAmostra(false);
    },[amostraSelecionada]);

    useEffect(() => {
        if(isChefeProjeto !== null)
            return;
        let projeto = projetos.find((p) => p.id === idProjeto);
        if (projeto) {
            setIsChefeProjeto(projeto.revisor)
        }
    },[projetos])

    const AtividadeCarregada = useCallback(({ referenciaForm }) => {
        const listaDependencias = [
            amostraSelecionada,
            amostras,
            areas,
            coletores,
            idAmostra,
            idArea,
            idEstacao,
            idSubArea,
            isChefeProjeto,
            listaAtividades,
            nomeProjeto,
            projetos,
            subAreas,
            isPossuiAssociacaoAmostra
        ]

        if (listaDependencias.some(biblioteca => biblioteca === undefined)) {
            return <></>;
        }
        if(isPossuiAssociacaoAmostra === true && !rochasAfloramento && !afloramentoAssociadoAmostra && !temAfloramento)
            return <></>
        return (<Petrografias
            referenciaForm={referenciaForm}
            idAmostra={idAmostra}
            idEstacao={idEstacao}
            area={idArea}
            subArea={idSubArea}
            nomeProjeto={nomeProjeto}
            atividades={listaAtividades}
            amostras={amostras}
            onMount={() => {}}
            valoresIniciais={calcularValoresIniciais()}
            mostraErroFichaPetrografica={false}
            coletores={coletores}
            projetos={projetos}
            areas={areas}
            subAreas={subAreas}
            isChefeProjeto={isChefeProjeto}
            bloquearFichaPetrografica={true}
            amostraSelecionada={amostraSelecionada}
            rochaAfloramento={rochasAfloramento}
            permitirVisualizacao={true}
            permitirGerarPdf={false}
            permitirEdicao={false}
            permitirInsercao={false}
            amostraRelacionadaComAfloramento={temAfloramento}
            afloramentoAssociadoAmostra={afloramentoAssociadoAmostra}
        />);

    }, [afloramentoAssociadoAmostra,
        amostraSelecionada,
        amostras,
        areas,
        coletores,
        idAmostra,
        idArea,
        idEstacao,
        idSubArea,
        isChefeProjeto,
        listaAtividades,
        nomeProjeto,
        projetos,
        rochasAfloramento,
        isPossuiAssociacaoAmostra,
        subAreas,
        temAfloramento]);

    return (
        <Form id="formAtividadePetrografias"
            onSubmit={() => { }}
            decorators={decoradores}
            initialValues={amostra}
            mutators={{ setValue }}
            render={(formProps) => {
                return (<AtividadeCarregada referenciaForm={formProps} />);
            }} />
    );
}

export { PetrografiasAprovacao };